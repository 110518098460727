import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { makeStyles, useTheme } from '@material-ui/core/styles';

import { 
  useMediaQuery, 
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  
  Typography,
} from '@material-ui/core';

import { LearnMoreLink, Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardProduct } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeight700: {
    fontWeight: 700,
  },
  coverImage: {
    objectFit: 'cover',
  },
  textWhite: {
    color: '#FFF',
  },
  textLightBlue: {
    color: '#A5ACD0',
  },
  textDarkBlue: {
    color: '#0F111B',
  },
}));

// Scenario will be a PureComponent (stateless, but still a class component) 
// that will open the Modal component with a supplied "description" and 
// "title" via a parent callback function named "handleOpenModal"

class Scenario extends PureComponent {
  openModal = props => {
    const { description, handleOpenModal, title, src, data, className, ...rest } = this.props;
    handleOpenModal({ description, title, src });
  };

  

  render = () => (
    <div className="scenario" onClick={this.openModal}>
      <Grid key={this.props.index} item xs={12} md={4} data-aos={'fade-up'}>
            <CardProduct
              withShadow
              liftUp
              mediaContent={
                <Image
                  // className={coverImage}
                  {...this.props.src}
                  src={this.props.src}
                  srcSet={this.props.src}
                  width='100%'
                  height='100%'
                  alt={this.props.title}
                  lazyProps={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              cardContent={
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      align="left"
                      // className={classes.fontWeight700}
                    >
                      {this.props.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LearnMoreLink title={'Learn more'} variant="body1" />
                    
                  </Grid>
                </Grid>
              }
            />
          </Grid>
    </div>
  );
}

Scenario.propTypes = {
  description: PropTypes.string.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default Scenario;