import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { SectionHeader } from 'components/molecules';
import { Accordion } from 'components/organisms';

const useStyles = makeStyles(() => ({
  root: {},
  textWhite: {
    color: 'white',
  },
  textDarkBlue: {
    color: '#0F111B',
  },
  textLightBlue: {
    color: '#A5ACD0',
  },
}));

const Working = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title={
          <span className={classes.textLightBlue}>
            Working at{' '}
            <span className={classes.textDarkBlue}>CETERIS</span>
            <span className={classes.textLightBlue}>{' '}|{' '}</span>
            <span className={classes.textDarkBlue}>AI</span>
          </span>
        }
        subtitle={<span>What's it like to work with us?</span>}
        fadeUp
      />
      <Accordion items={data} />
    </div>
  );
};

Working.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Working;
