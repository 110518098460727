import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Grid, useMediaQuery } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

import { NavLink as RouterLink } from 'react-router-dom';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <RouterLink {...props} />
  </div>
));

const useStyles = makeStyles(() => ({
  root: {},
  image: {
    maxWidth: 420,
  },
  textWhite: {
    color: '#FFF',
  },
  textLightBlue: {
    color: '#A5ACD0',
  },
  textDarkBlue: {
    color: '#0F111B',
  },
}));

const Story = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid
        container
        justify="space-between"
        spacing={isMd ? 4 : 2}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          justify="flex-start"
          xs={12}
          md={6}
          // data-aos={'fade-up'}
        >
          <SectionHeader
            title={
              <span className={classes.textLightBlue}>
                We don’t sell Services. We unlock{' '}
                <span className={classes.textDarkBlue}>
                  Business Value
                </span>
                .
                <br />
              </span>
            }
            subtitle={<span>Use exponential technologies to your advantage and lead your business with confidence through innovation.</span>}
            ctaGroup={[
              <Button variant="contained" color="primary" size="large" component={CustomRouterLink} to={"/contact-us"}>
                Start now
              </Button>,
              <Button variant="outlined" color="primary" size="large" component={CustomRouterLink} to={"/data-science"}>
                Learn more
              </Button>,
            ]}
            align="left"
            disableGutter
            titleVariant="h4"
            data-aos="zoom-in"
            data-aos-easing="zoom-out-sine"
            data-aos-duration="2000"
          />
        </Grid>
        <Grid
          item
          container
          justify={isMd ? 'flex-end' : 'flex-start'}
          alignItems="center"
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Image
            src="/images/illustrations/sev_calculator.png"
            alt="Our story"
            className={classes.image}
            data-aos="zoom-in"
            data-aos-easing="zoom-out-sine"
            data-aos-duration="2000"
          />
        </Grid>
      </Grid>
    </div>
  );
};

Story.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Story;
