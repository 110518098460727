export const advantages = [
  {
    icon: '/images/111010-data-analytics/svg/currency-value.svg',
    title: 'Increase Profits',
    subtitle:
      'Connect in spaces designed to bring incredible people together. Learn with them and take your project to new heights.',
  },
  {
    icon: '/images/111010-data-analytics/svg/data-analytics-descendant-graphic.svg',
    title: 'Lower Costs',
    subtitle:
      'Stay as little as 3 months with rolling contracts. Like it here? This is your space, so stay as long as you want.',
  },
  {
    icon: '/images/111010-data-analytics/svg/person-in-data-analytics-presentation-with-a-graphic-on-a-screen.svg',
    title: 'Increase Results',
    subtitle:
      'Monthly fee covers everything you need hassle free. Keep cool and focus on what matters to you.',
  },
  {
    icon: '/images/111010-data-analytics/svg/stock-dealing.svg',
    title: 'Improve Customer Service',
    subtitle:
      '24/7 support. No more hidden prices. It is your workingplace, playground, relax room.',
  },
  {
    icon: '/images/111010-data-analytics/svg/descending-data-analytics-line-graphic.svg',
    title: 'Reduced Time Spent on Tasks',
    subtitle:
      '24/7 support. No more hidden prices. It is your workingplace, playground, relax room.',
  },
  {
    icon: '/images/111010-data-analytics/svg/balancing-data.svg',
    title: 'Reduced Risk',
    subtitle:
      '24/7 support. No more hidden prices. It is your workingplace, playground, relax room.',
  },
];

export const focusAreas = [
  {
    title: 'Data Science',
    description:
      'From strategy, problem identification, and problem scoping to descriptive/diagnostic/predictive/prescriptive analytics, our data scientists are able to rapidly develop AI / machine learning models that provide proven business value to business stakeholders. Experienced in multiple AI specialities including machine learning, deep learning, computer vision, natural language processing and optimization, we connect the dots between business outcomes and AI.',
    icon: 'fa fa-flask',
  },
  {
    title: 'ML Engineering',
    description:
      'As data continues to grow exponentially, your data solutions need to scale accordingly. Getting your data science initiatives beyond the PoC, and "corporate science experiments", requires engineering skills and discipline. Our ML Engineers are able to help your organization rapidly adopt and scale up your ML capabilities through the use of scalable Big Data technologies like Elastic Stack (formerly ELK stack) and Spark.',
    icon: 'fa fa-cogs',
  },
];

export const technologies = [
  {
    name: 'Python',
    logo: '/images/logos/python-logo.svg',
  },
  {
    name: 'R',
    logo: '/images/logos/r-logo.svg',
  },
  {
    name: 'Scikit Learn',
    logo: '/images/logos/scikit-learn-logo.svg',
  },
  // {
  //   name: 'Tensorflow',
  //   logo: '/images/logos/tensorflow-logo.svg',
  // },
  {
    name: 'Spark',
    logo: '/images/logos/spark-logo.svg',
  },
  {
    name: 'Pytorch',
    logo: '/images/logos/pytorch-logo.svg',
  },
  {
    name: 'FastAPI',
    logo: '/images/logos/fastapi-logo.svg',
  },
  {
    name: 'Flask',
    logo: '/images/logos/flask-logo.svg',
  },
  {
    name: 'Tableau',
    logo: '/images/logos/tableau-logo.svg',
  },
  // {
  //   name: 'Elastic Stack',
  //   logo: '/images/logos/elastic-stack-logo.svg',
  // },
  // {
  //   name: 'Elasticsearch',
  //   logo: '/images/logos/elasticsearch2-logo.svg',
  // },
  // {
  //   name: 'Elastic Beats',
  //   logo: '/images/logos/elastic-beats-logo.svg',
  // },
  // {
  //   name: 'Elastic Logstash',
  //   logo: '/images/logos/elastic-logstash-logo.svg',
  // },
  {
    name: 'Apache Airflow',
    logo: '/images/logos/airflow-logo.svg',
  },
  // {
  //   name: 'Kubernetes',
  //   logo: '/images/logos/kubernetes-logo.svg',
  // },
  // {
  //   name: 'OpenShift',
  //   logo: '/images/logos/openshift-logo.svg',
  // },
  // {
  //   name: 'Apache Hive',
  //   logo: '/images/logos/apache-hive-logo.svg',
  // },
  // {
  //   name: 'Apache Hadoop',
  //   logo: '/images/logos/hadoop-logo.svg',
  // },
  // {
  //   name: 'Apache Beam',
  //   logo: '/images/logos/apache-beam-logo.svg',
  // },
];

export const industries = [
  {
    title: "Banking & Capital Markets",
    description: "banking banking banking",
    cover: {
      src: '/images/industries/banking-capital-markets.jpg',
      srcSet: '/images/industries/banking-capital-markets.jpg 2x',
    },
    usecases: [
      {
        type: "Fraud detection:",
        description: "using patterns presented in historical data aiming to detect fraud. Fraudulent characteristics can be logged and fed back into detection algorithms almost in real-time. Such a practice allows building models to predict the trustworthiness of future transactions. Having access to so much data creates the opportunity to detect anomalies in other more complex patterns of characteristics. This allows to warn that a transaction is suspect in a fast manner.",
      },
      {
        type: "Recommendation engines or AI assistants:",
        description: "can help to decide where to securely spend money by offering personalized recommendations based on habits and purchase history.",
      },
      {
        type: "Optimization of the mortgage process:",
        description: "AI systems analyze thousands of mortgage applications to determine where efficiencies can be made by reducing duplicate workloads and streamlining workflows between different parties. Machine Learning is used to process data across the workflow, and it can build accurate models and produce on-demand data-driven insights. Machine Learning and Deep Learning framework are deployed to drive analytical algorithms and enable quick access to big data. They help to make more accurate decisions based on consumers’ credit histories.",
      },
      {
        type: "Applying Machine Learning to the decision scoring system:",
        description: "the process is executed when a merchant terminal passes a customer’s card details through card issuers’ systems for verification at the point of purchase. The models are used to decide whether a payment is legitimate and updated in real-time. This is being executed based on data gathered from all the billions of transactions that the issuer processes.",
      },
      {
        type: "Robotic Process Automation:",
        description: "software bots are deployed to handle static, typically data management-related tasks. Particularly useful to the institutional buy-side and large investment banks, RPA has demonstrated its potential to handle a wide range of operational functions: trade processing and support, reconciliations and case management, various aspects of fund administration and client portfolio rebalancing.",
      },
      {
        type: "",
        description: "Machine Learning solutions can shore things up operationally while enabling trading desks and business units to collect information faster and ultimately increase margins. This can be achieved through tactical trading signal discovery or expanded on a strategic level via algorithms that can analyze millions of client data points across portfolio management, risk, underwriting and counterparty credit analysis.",
      },
      {
        type: "",
        description: "Deep Learning (Neural Networks) is focused more on Data Science and a reconceptualization of finance at the modelling and engineering levels. Most often in use at quantitative hedge funds and proprietary trading firms, Deep Learning AI combines advances in dataset nuances - in semantic analysis and geospatial imagery recognition and Neural Network infrastructures to complete complex exercises. These exercises include predictive pricing and portfolio construction, such as long short-time memory.",
      },
      {
        type: "",
        description: "Digital banks and loan-issuing apps use Machine Learning algorithms to use alternative data (e.g., smartphone data) to evaluate loan eligibility and provide personalized options.",
      },
      {
        type: "Risk management:",
        description: "Enormous Processing Power allows vast amounts of data to be handled in a short time. Cognitive computing helps to manage both structured and unstructured data. Algorithms analyze the history of risk cases and identify early signs of potential future issues.",
      },
      {
        type: "Personalized Financial Planning:",
        description: "capital markets have been making the most of digital assistants, to render better service interaction and transform sales. For enhanced interaction with the clients, virtual assistants offer conversational interfaces that rely upon several AI technologies (such as Machine Learning, Language Processing). AI then understands the intent of the user and makes real-time responses based on algorithms.",
      },
      {
        type: "",
        description: "Intelligent Trading Systems monitor both structured (such as databases, spreadsheets) and unstructured (such as social media, news) data. The predictions for stock performance are more accurate because algorithms can test trading systems based on past data and bring the validation process to a whole new level before pushing it live. AI puts together recommendations for the most robust portfolios depending on a specific investor’s short- and long-term goals. Multiple financial institutions also trust AI to manage their entire portfolios.",
      },
      {
        type: "",
        description: "Digital personal assistants and chatbots have revolutionized customer services and business communication. AI-powered smart chatbots and Voice-controlled virtual assistants provide clients with comprehensive self-help solutions while reducing the call-centers’ workload. Both tools can check balances, schedule payments, look up account activity and more. Several apps offer personalized financial advice and help individuals achieve their financial goals. These intelligent systems track income, essential recurring expenses and spending habits. This allows to come up with an optimized plan and financial tips.",
      },
      {
        type: "Liquidity risk analysis:",
        description: "multi-dimensional risk and exposure data analytics.",
      },
      {
        type: "Visualization of market risk:",
        description: "using Computer Vision techniques to analyze volatility services.",
      },
      {
        type: "Sentiment analysis:",
        description: "to determine client needs/opportunities - social media analytics.",
      },
      {
        type: "Natural Language document analysis services:",
        description: "analyzing and extracting key data from unstructured or semi-structured documents.",
      },
      {
        type: "Internal compliance:",
        description: "for identifying patterns and behaviors analysis of trader activities for market abuse surveillance.",
      },
      {
        type: "Identifying dataset anomalies and cleansing data:",
        description: "recognizing distinct categories from unstructured free text.",
      },
      {
        type: "IT support analysis on system outages and root cause:",
        description: "detection of cyber-attacks.",
      },
    ],
  },
  {
    title: "Healthcare",
    description: "healthcare healthcare healthcare.",
    cover: {
      src: '/images/industries/healthcare.jpg',
      srcSet: '/images/industries/healthcare.jpg 2x',
    },
    usecases: [
      {
        type: "Advanced clinical decision support:",
        description: "platform, which uses Natural Language Processing and Machine Learning to suggest the optimal treatment pathway for patients. Platform correlates data from patient records as well as research archives and analyzes the outcomes. To conduct analysis, analytical tools utilize Natural Language Processing to understand the contents in databases and patient records",
      },
      {
        type: "Deep Learning medical tools to streamline radiology diagnoses:",
        description: "Deep Learning platform analyzes unstructured medical data (such as radiology images, blood tests, EKGs, genomics, patient medical history) to give doctors better insight into a patient’s real-time needs.",
      },
      {
        type: "",
        description: "Machine Learning technology is used to assist pathologists in making more accurate diagnoses, reduce errors in cancer diagnosis and develop methods for individualized medical treatment.",
      },
      {
        type: "",
        description: "An intelligent symptom checker - a chatbot listens to a patient’s symptoms and health concerns, then guides that patient to the correct care based on its diagnosis.",
      },
      {
        type: "",
        description: "AI is used to diagnose potentially deadly blood diseases at a very early stage. Doctors are using AI-enhanced microscopes to scan for harmful bacteria (like E. coli and staphylococcus) in blood samples at a faster rate than is possible using manual scanning.",
      },
      {
        type: "",
        description: "AI-enabled assistants that receive imaging scans and automatically analyze them for various clinical findings they have studied. The findings are passed onto radiologists, who take the assistants’ reports into consideration when making a diagnosis.",
      },
      {
        type: "",
        description: "AI is used to identify and develop new medicines in the fields of immuno-oncology and neuroscience. Additionally, AI might be employed to find new applications for existing drugs or to identify new patients.",
      },
      {
        type: "",
        description: "AI is used to get the right treatment to the right patients at the right time to produce a better target selection as well as provide previously undiscovered insights through Deep Learning.",
      },
      {
        type: "",
        description: "AI platforms can be designed to automate the healthcare industry's most repetitive tasks, freeing up administrators to work on higher-level ones. The platforms automate everything from eligibility checks to un-adjudicated claims and data migrations so staff can focus on providing better patient service.",
      },
      {
        type: "",
        description: "AI is used to provide personalized and interactive healthcare, including anytime face-to-face appointments with doctors. AI-powered chatbots streamline the review of a patient’s symptoms, then recommends either a virtual check-in or a face-to-face visit with a healthcare professional.",
      },
      {
        type: "",
        description: "The Digital nurse is used to helping people monitor patient’s conditions and follow up with treatments, between doctor visits. The program uses Machine Learning to support patients, specializing in chronic illnesses.",
      },
    ],
  },
  {
    title: "Energy (Oil & Gas)",
    description: "Energy",
    cover: {
      src: '/images/industries/oil-and-gas.jpg',
      srcSet: '/images/industries/oil-and-gas.jpg 2x',
    },
    usecases: [
      {
        type: "",
        description: "One of the most noticeable impacts of Machine Learning in Oil & Gas is how it transforms discovery processes. Applications that employ Machine Learning enable computers to quickly and accurately analyse huge amounts of data to scrutinize precisely through signals and noise in seismic data.",
      },
      {
        type: "",
        description: "Machine Learning algorithms can accurately model remote, hard to reach locations. This is being done by using models of well explored and documented areas that have similar geology.",
      },
      {
        type: "",
        description: "Deep Learning and Machine Learning in Oil & Gas extraction processes can also help to improve subsurface characterization. Applications provide insights into the fluid composition and inform the users of the pore size distribution of a formation.",
      },
      {
        type: "",
        description: "Using Machine Learning in Oil & Gas exploration through reservoir modelling allows users to predict how formations will react to certain drilling techniques.",
      },
      {
        type: "Drilling automation:",
        description: "drilling can involve a significant number of people trying to carry out their individual, complex tasks. Each of these tasks must be carried out safely and correctly, often within a set time frame, for the rest of the operations to run smoothly. Being able to access real-time information detailing all the operations is a valuable tool. Applying Machine Learning in Oil & Gas extraction in this way makes the industry safer and more efficient.",
      },
      {
        type: "Intelligent robots:",
        description: "robots designed with AI capabilities for hydrocarbon exploration, as well as production to improve productivity and cost-effectiveness while reducing worker risk.",
      },
      {
        type: "Virtual assistants:",
        description: "online chat platforms that help customers navigate product databases and process general inquiries using Natural Language.",
      },
    ],
  },
  {
    title: "Law",
    description: "Legal",
    cover: {
      src: '/images/industries/legal.jpg',
      srcSet: '/images/industries/legal.jpg 2x',
    },
    usecases: [
      {
        type: "",
        description: "COMING SOON",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
    ],
  },
  {
    title: "Retail & Consumer Goods",
    description: "Retail",
    cover: {
      src: '/images/industries/retail.jpg',
      srcSet: '/images/industries/retail.jpg 2x',
    },
    usecases: [
      {
        type: "",
        description: "Sales assistant software is designed to automate and enhance sales operations processes by identifying and conversing with internet leads.",
      },
      {
        type: "",
        description: "Cognitive Computing technology is used to help customers determine their needs and generate recommendations based on the history of their purchases or products purchased by similar profile customers.",
      },
      {
        type: "",
        description: "Chatbots replicating the role of a concierge - more sophisticated chatbots tend to be referred to as 'virtual assistants' as they can access more extensive knowledge bases and augmenting humans in more complex online tasks.",
      },
      {
        type: "",
        description: "AI-based software designed to make the entire manufacturing process - from design to distribution and services. The software includes a suite of analytics and operational intelligence tools that allow workers to collect granular-level data throughout production and reduce defective products as well as wasted productivity.",
      },
      {
        type: "",
        description: "Accelerating Deep Learning in robots to enable them learning a manufacturing task using a sensor that collects and stores data. Once the robot’s learning process is complete, the trained robots are then able to complete a task autonomously.",
      },
      {
        type: "",
        description: "Sensors can help to navigate the best travel path for delivery of products to end customers.",
      },
      {
        type: "",
        description: "AI-powered Check-out-free technology allows customers to shop and leave.",
      },
      {
        type: "",
        description: "Deep Learning approach is used to transaction security while making online digital transactions.",
      },
      {
        type: "",
        description: "Autonomous vehicles (e.g., pallets, cranes, conveyors and stacking equipment) have a widespread impact on consumer goods value chains. For example, robotic shelf-auditing robots and small parcel delivery via drones.",
      },
      {
        type: "",
        description: "'Smart warehouses' involve robots moving around the warehouse on a three-dimensional grid, moving and retrieving goods based on instructions from fulfillment and logistics platforms. Carry capacity and sensing technology (including Machine Vision and LIDAR) improves in capability each year and the cost of ownership decreases.",
      },
      {
        type: "",
        description: "Visual recognition across the value chain – advisors, which can analyze a selfie and then produce personalized skincare advice, “connected retail experiences” in-stores that use visual recognition to provide recommendations on apparel and makeup, food models making dietary tracking simpler for consumers, ability to find cases of unauthorized usage of logos and trademarks (e.g. in social media).",
      },
      {
        type: "",
        description: "Voice and Natural Language Processing capabilities in devices allow to take specific buying or consulting actions.",
      },
      {
        type: "",
        description: "Automated inventory management allows taking photos of store shelves to initiate Machine Learning process that automatically detects missing or misplaced items and notifies stakeholders to restock or make corrections.",
      },
      {
        type: "",
        description: "Image-recognition technologies can ease the procurement process and reduce the time it takes to send an order. Employees can just take a photo of an item to activate an automated database search for the exact item or an equivalent product.",
      },
      {
        type: "",
        description: "Personalized customer service - chatbots or voice assistants powered by Natural Language Processing and Machine Learning, companies can tap consumer shopping data and history to provide hyper-personalized and automated customer service.",
      },
      {
        type: "",
        description: "By monitoring customers’ social media behavior, AI can be used to analyze consumer data and identify behaviors that are crucial not only to improve customer service but also in the development and design of new product lines.",
      },
      {
        type: "",
        description: "Analyzing the picture to offer the most accurate look possible and personalized product recommendations.",
      },
      {
        type: "",
        description: "Combining AI-powered customer data with retail-specific marketing data to create an effective strategy for brands. Additionally, it helps in identifying emerging user behavior across various digital channels to deliver key insights.",
      },
      {
        type: "",
        description: "AI solution that uncovers and generates language for a retailer’s specific audience to drive short-term sales and long-term relationships. With a current focus on email and social media channels, marketers can use the tool to predict response rates, generate better marketing pushes and refine their brand’s tone of voice.",
      },
      {
        type: "",
        description: "360-degree vision of its customers - utilizing AI to better understand and engage with the customer base, with the ability to anticipate their needs and expectations and interact with them on the channel that suits them best.",
      },
      {
        type: "",
        description: "Predictive Intelligence enables to make informed recommendations on the companies’ websites and in personalized email campaigns.",
      },
      {
        type: "",
        description: "Visual search tool app allows shoppers to search for specific brand products through photographs they take while out and about. The app will recommend similar products available on the dedicated stores.",
      },
      {
        type: "",
        description: "Computer Vision technology such as image recognition tools can analyze millions of social media images to help a brand understand when, how and by whom products are enjoyed.",
      },
      {
        type: "",
        description: "Natural Language technology can provide the same level of customer service as a human telephone operator. Voice assistants use Natural Language Processing to interpret what it should do based on what a person says. They use Neural Networks and Deep Learning to become better at understanding how people talk and what they mean.",
      },
      {
        type: "",
        description: "Facial image analytics technology is used to interpret the data collected through pre-recorded video interviews. Computer Vision and Natural Language Processing technology are used to analyze the videos to capture data points that can be autonomously labeled to give readings indicative of characteristics.",
      },
      {
        type: "",
        description: "AI-powered robots operate in the same manner as Autonomous Vehicles and can recognize obstacles in their patch in real-time, equipped with extendable cameras and sensors for screening surfaces. They are designed to operate in public environments by applying Machine Learning algorithms.",
      },
    ],
  },
  {
    title: "Insurance",
    description: "Insurance",
    cover: {
      src: '/images/industries/lending.jpg',
      srcSet: '/images/industries/lending.jpg 2x',
    },
    usecases: [
      {
        type: "",
        description: "Machine Learning is used to help patients and employers select the most relevant and cost-effective health insurance coverage.",
      },
      {
        type: "",
        description: "Machine Learning is used to analyze medical claims and detect patterns that may signify health insurance fraud or waste (underutilized services).",
      },
      {
        type: "",
        description: "AI tools are analyzing data from wearable devices and other AI-driven ‘wellness engines’ to track health vitals, provide healthy living advice and risk reports to individual users.",
      },
      {
        type: "",
        description: "Utilization of AI-based software to analyze car accident damage and process claims through machine vision. This enables drivers to take pictures of their damaged car using their smartphone cameras. The tool then compares the photo with its image database to determine the severity of damage, estimate repair costs and analyze the accident’s impact on the driver’s future insurance premiums.",
      },
      {
        type: "Faster, customized claims settlement:",
        description: "online interfaces and virtual claims adjusters will make it more efficient to settle and pay claims following an accident, while simultaneously decreasing the likelihood of fraud. Additionally, virtual assistants are designed to answer common queries of insurance agents who had switched from selling one insurance product to another. The tool uses Natural Language Processing to address inquiries.",
      },
      {
        type: "Customer experience and coverage personalization:",
        description: "AI can enable a seamless automated buying experience, using chatbots that can pull on customers’ geographic and social data for personalized interactions. Carriers will also allow users to customize coverage for specific items and events (known as on-demand insurance).",
      },
      {
        type: "Fraud detection and credit analysis:",
        description: "through the AI-driven predictive analytics software to process thousands of claims each month. By analyzing the claims in milliseconds based on set rules and indicators, AI can identify which may not be legitimate, reducing the number of fraudulent claims. These indicators include things such as frequency of claims, past behavior and credit score.",
      },
      {
        type: "Customer profiling and segmentation:",
        description: "by automating and applying Cognitive Learning to their data collection processes, insurance companies are advancing their customer profiling capabilities. Equipped with the power to unify and derive insights from their internal and external customer data, insurers can build a more comprehensive picture of their customers (such as their insurance needs, interests and life stages) for more effective targeting. Insurers can segment their audience based on these attributes and use Deep Learning to predict the conversion rate of these segments.",
      },
      {
        type: "Product and policy design:",
        description: "by streamlining, speeding up the collection and analysis of massive data from owned channels, third-party sources, and agents, insurers can use Machine Learning to discover customer trends and interests in real-time. These insights are used to develop and improve product and policy design.",
      },
      {
        type: "Underwriting and claims assessment:",
        description: "the advanced analytics and Machine Learning, as well as additional sources such as satellites and the IoT devices, enable to get a more holistic view of risk, as well as to determine which submissions to review in the first place.",
      },
    ],
  },
  {
    title: "Audit & Compliance",
    description: "Audit",
    cover: {
      src: "/images/industries/lending.jpg",
      srcSet: '/images/industries/lending.jpg 2x',
    },
    usecases: [
      {
        type: "",
        description: "COMING SOON",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
    ],
  },
  {
    title: "Real Estate",
    description: "Real Estate",
    cover: {
      src: "/images/industries/buildings.jpg",
      srcSet: '/images/industries/buildings.jpg 2x',
    },
    usecases: [
      {
        type: "Effective lead management:",
        description: "AI systems scan piles of data patterns drawn from historical sales records to identify the most demanded property types. This information can be of help to marketers, salespeople and brokers in their advertising activities.",
      },
      {
        type: "Automated Valuation Models (AVM):",
        description: "can estimate the value of a property based on numerous factors. Various AVM algorithms use a different combination of data to value property (e.g., historical data, age of a home, images of a house, market value, trends, neighborhood). Because the analysis involves such a wide variety of sources, AVMs often give a more accurate property valuation than that made by a real estate agent.",
      },
      {
        type: "Intelligent Chatbot Assistants:",
        description: "can handle most customer care queries very quickly, can respond to more specific and complex queries, as well as ready to interact with potential customers 24/7. These utilize Machine Learning functionality to analyze information, allowing computers to estimate the value of a property. Essentially, the tool helps clients to find the perfect property without dealing with an actual estate agent or property investor.",
      },
      {
        type: "Providing recommendations to customers:",
        description: "AI can create tailor-made listings of properties based on a client’s preferences and previous viewings. Additionally, they can use profiling techniques to provide first-time customers with relevant offers based on demographical attributes or things that worked well with similar past clients.",
      },
      {
        type: "Performing marketing analyses:",
        description: "with the right combination of human expertise and data-based analyses and forecasting, AI can predict future rent and sale prices, identify the latest market trends, and even pinpoint new investment opportunities.",
      },
      {
        type: "Smart Property Analysis:",
        description: "AI real estate software can make a faster and more accurate calculation regarding various aspects of a property. For example, detailed annual sunlight amount, time of best light during the day, sound disturbance, parking space availability, plowing.",
      },
      {
        type: "Aid in Property Development:",
        description: "when fed with accurate datasets, AI software can suggest plots or building sites based on city zoning, trends in the industry, currently available as well as future local infrastructure.",
      },
      {
        type: "Mortgage Calculation:",
        description: "smart AI algorithms can quickly review mortgage applications screening them for completeness or errors. AI can also be employed to calculate income, analyze key documents and check various income sources.",
      },
      {
        type: "Automation of Manual Tasks:",
        description: "reports, legal papers, zoning regulations, requests for proposals, various analyses, or agreements are just a few of the documents that real estate transactions generate. AI-powered tools can review these documents, scanning them for any inaccuracies and inconsistencies (such as missing signatures, empty fields, invalid characters).",
      },
    ],
  },
  {
    title: "Telecommunications",
    description: "Telecommunications",
    cover: {
      src: "/images/industries/telecoms.jpg",
      srcSet: '/images/industries/telecoms.jpg 2x',
    },
    usecases: [
      {
        type: "",
        description: "COMING SOON",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
      {
        type: "",
        description: "",
      },
    ],
  },
];
