import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Contact, FocusAreas, Hero, Story, WhoWeAre } from './components';
import { Section, SectionAlternate } from 'components/organisms';

import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
// import Fab from '@material-ui/core/Fab';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';

import {
  // partners,
  advantages,
  // reviews,
  // integrations,
  // customizations,
  // team,
} from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  WhoWeAreSection: {
    background: theme.palette.primary.light,
  },
  ContactSection: {
    background: theme.palette.primary.light,
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Home = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hero />
      {/* <ScrollTop {...props}>
        <Fab color="textPrimary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop> */}
      <Section className={classes.sectionNoPaddingTop}>
        <WhoWeAre id="whoWeAre" />
      </Section>
      <SectionAlternate className={classes.sectionNoPaddingTop}>
        <Story id="story" />
      </SectionAlternate>
      <Section className={classes.sectionNoPaddingTop}>
        <FocusAreas data={advantages}  />
      </Section>
      <SectionAlternate className={classes.sectionNoPaddingTop, classes.ContactSection}>
        <Contact id="contact" />
      </SectionAlternate>
    </div>
  );
};

export default withRouter(Home);
