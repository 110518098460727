import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Typography, IconButton, Grid } from '@material-ui/core';
import { Icon, LearnMoreLink } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardBase, DescriptionListIcon } from 'components/organisms';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import {
  ArrowRight,
  Mail,
  MapPin,
  Phone,
} from "react-feather";

import { NavLink as RouterLink } from 'react-router-dom';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <RouterLink {...props} />
  </div>
));

const useStyles = makeStyles(theme => ({
  root: {
  },
  title: {
    fontWeight: 'bold',
  },
  icon: {
    padding: 0,
    marginLeft: theme.spacing(1),
    '&:hover': {
      background: 'transparent',
    },
  },
  learnMoreLink: {
    marginTop: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  textWhite: {
    color: '#FFF',
  },
  textLightBlue: {
    color: '#A5ACD0',
  },
  textDarkBlue: {
    color: '#0F111B',
  },
}));

const FocusAreas = props => {
  const { data,
    className,
    color,
    component,
    variant,
    title,
    href,
    iconProps,
    typographyProps,
    ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} data-aos="fade-up" {...rest}>
      <SectionHeader
        title={
          <span><span className={classes.textDarkBlue}>CETERIS</span><span className={classes.textLightBlue}>{' '}|{' '}</span><span className={classes.textDarkBlue}>AI</span>
          <span className={classes.textLightBlue}>{' '}helps you{' '}
            <span className="text-highlighted">
              Unlock the Business Value of your Data
            </span>
          </span></span>
        }
        subtitle={<span>Our team of highly skilled Strategists, Machine Learning Engineers, and Data Scientists will partner with your team to rapidly develop and operate predictive analytics and AI / machine learning models to realize impactful and demonstrable value to your business. We go to great lengths to provide you with the best, highest-quality solutions. Our primary areas of focus are:</span>}
        fadeUp
      />
      <Grid container spacing={isLg ? 10 : 2}>
        {data.map((item, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            data-aos="fade-up"
          >
            <CardBase liftUp variant="outlined">
              <DescriptionListIcon
                title={item.title}
                subtitle={item.description}
                icon={
                  <Icon
                    fontIconClass={item.icon}
                    size="large"
                    color={'#0F111B'}
                  />
                }
              />
              <LearnMoreLink
                title={'Learn more'}
                variant="body1"
                color="secondary"
                to={item.link}
                className={classes.learnMoreLink}
                />
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

FocusAreas.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default FocusAreas;
