import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, GridList, GridListTile } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    objectFit: 'cover',
    borderRadius: theme.spacing(0.5),
    borderColor: '#0F111B',
  },
  textDark: {
    color: '#0F111B',
  },
  gridListTile: {
    position: 'relative',
  },
  gridListSection: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const MLComponents = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title="ML Engineering"
        subtitle="Taking solutions from Idea to Production."
        data-aos="fade-up"
      />
      <GridList cellHeight={isMd ? 90 : 70} cols={10} spacing={isMd ? 12 : 8}>
        {data.map((item, index) => (
          <GridListTile
            key={index}
            cols={isMd ? item.cols : 8 || 1}
            className={classes.gridListTile}
          >
            <Image
              {...item.image}
              alt={item.location}
              className={classes.image}
              style={{
                opacity: '100%',
                backgroundColor: item.backgroundColor
              }}
              lazyProps={{
                width: '100%',
                height: '100%',
                border: '1px',
              }}
            />
            <SectionHeader
              subtitle={<span className={classes.textDark}>{item.component}</span>}
              // subtitle={
              //   <span className={classes.textWhite}>
              //     {item.summary}
              //   </span>
              // }
              disableGutter
              className={classes.gridListSection}
              align="left"
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
};

MLComponents.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default MLComponents;
