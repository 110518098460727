import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useMediaQuery, Grid, Tooltip, Button } from '@material-ui/core';
import { LearnMoreLink, Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';

const useStyles = makeStyles(() => ({
  root: {},
  logo: {
    maxWidth: 50,
  },
}));

const Technologies = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} md={6} data-aos="fade-up">
              <Grid container spacing={2}>
                {data.map((item, index) => (
                  <Grid item xs={4} key={index}>
                    <CardBase withShadow liftUp>
                      <Tooltip title={item.name} arrow>
                        <Button>
                        <Image
                          src={item.logo}
                          alt={item.name}
                          className={classes.logo}
                          lazy={false}
                        />
                        </Button>
                      </Tooltip>
                    </CardBase>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <SectionHeader
                title="We leverage open source tools wherever possible to maximize flexibility and reduce vendor lock-in"
                subtitle="Our mission is to help you improve your business through innovation and optimization."
                align="left"
                label="Industry Standard Tools"
                // ctaGroup={[
                //   <LearnMoreLink
                //     title="See all integrations"
                //     href="#"
                //     variant="h6"
                //   />,
                // ]}
                disableGutter
                data-aos="fade-up"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Technologies.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Technologies;
