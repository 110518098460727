import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { Grid, colors, Tooltip, Button, useMediaQuery  } from '@material-ui/core';
import { Icon, Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardBase, DescriptionListIcon } from 'components/organisms';

import { NavLink as RouterLink } from 'react-router-dom';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <RouterLink {...props} />
  </div>
));

const useStyles = makeStyles(() => ({
  root: {},
  icon: {
    maxWidth: '50%',
    maxHeight: '50%',
    color: '#0F111B',
  },
  textWhite: {
    color: '#FFF',
  },
  textLightBlue: {
    color: '#A5ACD0',
  },
  textDarkBlue: {
    color: '#0F111B',
  },
}));

const Advantages = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {/* <SectionHeader
        title={
          <span><span className={classes.textDarkBlue}>Intelligent Innovation</span>
          <span className={classes.textLightBlue}>{' '}help you{' '}
            <span className="text-highlighted">
              Unlock the Business Value of your Data
            </span>
          </span></span>
        }
        subtitle={<span>Applied Innovative Ways To Transform Industries And Deliver Results Beyond Expectations.</span>}
        fadeUp
      /> */}
      <Grid container spacing={isMd ? 3 : 2}>
        <Grid item xs={12}>
          <Grid container spacing={isMd ? 3 : 2}>
            <Grid item xs={12} md={6}>
              <SectionHeader
                title={
                  <span>
                    <span className={classes.textLightBlue}>
                      Intelligent innovation to {' '}
                    </span>
                    <span className={classes.textDarkBlue}>
                      Transform Industries {' '}
                    </span>
                    <span className={classes.textLightBlue}>
                      and deliver results {' '}
                    </span>
                    <span className={classes.textDarkBlue}>
                      Beyond Expectations.
                    </span>
                  </span>}
                subtitle="Do you want to streamline operations, maximize efficiency, boost saving and gain more profit? The benefits of AI, Machine Learning and Data Science-based offerings include:"
                align="left"
                label="Intelligent Innovation"
                ctaGroup={[
                  <Button variant="contained" color="primary" size="large" component={CustomRouterLink} to={"/contact-us"}>
                    Contact
                  </Button>,
                ]}
                disableGutter
                data-aos="fade-up"
              />
            </Grid>
            <Grid item xs={12} md={6} data-aos="fade-up">
              <Grid container spacing={3}>
                {data.map((item, index) => (
                <Grid key={index} item xs={12} md={3} data-aos="fade-up" align="center">
                  <Image
                    src={item.icon}
                    alt={item.title}
                    className={classes.icon}
                    color="#0F111B"
                    lazy={false}
                  />
                  <DescriptionListIcon
                    title={item.title}
                    // subtitle={item.subtitle}
                    // icon={item.icon}
                  />
                </Grid>
              ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Advantages.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Advantages;
