import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@material-ui/core";

import { Image } from 'components/atoms';


// "Modal is a pure function that requires 4 parameters in order to display 
// the "<Modal />" component. This function would be the same as a traditional function:
// 
//   function Modal({ deconstructed parameters }) { 
//     return (
//       <Dialog>
//        ...
//      </Dialog>
//     )
//   }

const Modal = ({ description, onCloseModal, openModal, title, src }) => ( 
  <Dialog
    open={openModal}
    onClose={onCloseModal}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title" align="center">
      <Image
        // className={classes.coverImage}
        src={src}
        srcSet={src}
        alt={title}
        lazyProps={{
          width: '50%',
          height: '20%',
        }}
      />
      <br />
      {title}
      <Divider />
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <strong><u>Examples of AI-based tools include but not limited to the following:</u></strong>
        <br /><br />
        {description}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCloseModal} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

// PropTypes throws a warning if any of the 4 required params are
// missing! In addition, it ensures that props are consistent in name
// and declaration from parent component to child component.

Modal.propTypes = {
  description: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

export default Modal;