import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, colors, Divider } from '@material-ui/core';
import { Icon, Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { DescriptionListIcon, Section } from 'components/organisms';

const useStyles = makeStyles(() => ({
  root: {},
}));

const Approach = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title={
          <span>
            Our Approach
          </span>
        }
        subtitle="CETERIS.AI is a Machine Learning Consultancy experienced in applying Artificial Intelligence and Machine Learning to business problems. Energetic, smart and passionate about the clients we serve, we approach your business by upholding the highest professional standards. We are committed to helping your business realize the value of AI-enabled solutions with a disciplined focus on business outcomes."
        fadeUp
      />
      <Divider />
      <Image
        src="/images/illustrations/data_science_process.png"
        srcSet="/images/illustrations/data_science_process.png 2x"
        alt="About"
        className={classes.image}
        lazyProps={{
          width: '100%',
          height: '100%',
        }}
        data-aos="zoom-in"
        data-aos-easing="zoom-out-sine"
        data-aos-duration="2000"
      />
      <Divider />
      <Section />
      <Grid container spacing={4}>
        {data.map((item, index) => (
          <Grid key={index} item xs={12} md={3} data-aos="fade-up">
            <DescriptionListIcon
              title={item.title}
              subtitle={item.subtitle}
              icon={
                <Icon
                  fontIconClass={item.icon}
                  size="medium"
                  fontIconColor={colors.indigo[500]}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Approach.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Approach;
