import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import {
//   AppBar,
//   Toolbar,
//   Drawer,
//   IconButton,
//   Divider,
// } from '@material-ui/core';
// import ForumIcon from '@material-ui/icons/Forum';
import { SectionAlternate } from 'components/organisms';
import {
  Approach,
  Contact,
  // Application,
  // Community,
  // Events,
  Hero,
  Leadership,
  Philosophy,
  Process,
  // Locations,
  // MapHero,
  // Props,
  // Reviews,
  // Spaces,
} from './components';

import {
  // mapData,
  approach,
  people,
  philosophy,
  // locations,
  // properties,
  // reviews,
  // events,
  // gallery,
} from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  appBarBottom: {
    top: 'auto',
    bottom: 0,
    background: 'transparent',
    boxShadow: 'none',
  },
  toolbarBottom: {
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
  },
  chatIconButton: {
    position: 'absolute',
    right: theme.spacing(3),
    left: 'auto',
    top: theme.spacing(-3),
    background: theme.palette.primary.main,
    width: 55,
    height: 55,
    boxShadow: '0 2px 10px 0 rgba(23,70,161,.11)',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  forumIcon: {
    color: 'white',
    width: 25,
    height: 25,
  },
  LeadershipSection: {
    background: theme.palette.primary.light,
  },
  ProcessSection: {
    background: theme.palette.primary.dark,
  },
}));

const About = props => {
  const classes = useStyles();

  // const [openBottombar, setOpenBottombar] = React.useState(false);

  // const handleBottombarOpen = () => {
  //   setOpenBottombar(true);
  // };

  // const handleBottombarClose = () => {
  //   setOpenBottombar(false);
  // };

  return (
    <div className={classes.root}>
      <Hero />
      <SectionAlternate>
        <Approach data={approach} />
      </SectionAlternate>
      <Philosophy data={philosophy} />
      <SectionAlternate className={ classes.sectionNoPaddingTop, classes.ProcessSection } >
        <Process data={approach} />
      </SectionAlternate>
      <SectionAlternate >
        <Leadership data={people} />
      </SectionAlternate>
      <SectionAlternate className={ classes.sectionNoPaddingTop, classes.LeadershipSection }>
        <Contact />
      </SectionAlternate>
      {/* <Section>
        <Locations data={locations} />
      </Section>
      <Divider />
      <Section>
        <Spaces />
      </Section>
      <SectionAlternate>
        <Props data={properties} />
      </SectionAlternate>
      <MapHero data={mapData} />
      <Section>
        <Reviews data={reviews} />
      </Section>
      <Divider />
      <Section>
        <Application />
      </Section>
      <SectionAlternate>
        <Events data={events} />
      </SectionAlternate>
      <Section narrow>
        <Community data={gallery} />
      </Section> */}
    </div>
  );
};

export default withRouter(About);
