import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Topbar, Footer, Sidebar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const pages = {
    company: {
      title: 'Company',
      id: 'company-pages',
      children: {
        company: {
          groupTitle: 'Company',
          pages: [
            {
              title: 'About Us',
              href: '/about',
            },
            // {
            //   title: 'Our Approach',
            //   href: '/about',
            // },
            // {
            //   title: 'Our Philosophy',
            //   href: '/about',
            // },
            // {
            //   title: 'Our Process',
            //   href: '/about',
            // },
            // {
            //   title: 'Our Leadership',
            //   href: '/about',
            // },
          ],
        },
        career: {
          groupTitle: 'Career',
          pages: [
            {
              title: 'Working @ CETERIS.AI',
              href: '/career',
            },
            // {
            //   title: 'Jobs',
            //   href: '/career',
            // },
          ],
        },
        helpCenter: {
          groupTitle: 'Help center',
          pages: [
            {
              title: 'Overview',
              //href: '/help-center',
              href: '/',
            },
            {
              title: 'FAQ',
              // href: '/help-center-article',
              href: '/',
            },
          ],
        },
        contact: {
          groupTitle: 'Contact',
          pages: [
            {
              title: 'Our Offices',
              href: '/contact-us',
            },
          ],
        },
        blog: {
          groupTitle: 'Blog',
          pages: [
            {
              title: 'Newsroom',
              href: '/',
            },
          ],
        },
      },
    },
    services: {
      title: 'Business Value',
      id: 'services-pages',
      children: {
        data_science: {
          groupTitle: 'Data Science',
          pages: [
            {
              title: 'Learn More',
              href: '/data-science',
            },
            // {
            //   title: 'Intelligent Innovation',
            //   href: '/data-science',
            // },
            // {
            //   title: 'Industry Use Cases',
            //   href: '/data-science',
            // },
            // {
            //   title: 'Our Toolset',
            //   href: '/data-science',
            // },
          ],
        },
        ml_engineering: {
          groupTitle: 'ML Engineering',
          pages: [
            {
              title: 'Learn More',
              href: '/ml-engineering',
            },
            // {
            //   title: 'Elastic Stack (formerly ELK)',
            //   href: '/',
            // },
            // {
            //   title: 'Spark',
            //   href: '/',
            // },
            // {
            //   title: 'AWS',
            //   href: '/',
            // },
            // {
            //   title: 'Azure',
            //   href: '/',
            // },
            // {
            //   title: 'Google Cloud Platform (GCP)',
            //   href: '/',
            // },
          ],
        },
      },
    },
  };

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} pages={pages} />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main>{children}</main>
      <Footer pages={pages} />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
