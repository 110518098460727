import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

// import { Grid, colors, Divider, Typography } from '@material-ui/core';
// import { Icon, Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
// import { DescriptionListIcon, Section, SectionAlternate } from 'components/organisms';

// vertical timeline
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import { 
  BlurOff,
  Chat,
  CheckBoxOutlined as CheckBox,
  DeveloperBoard,
  LockOutlined as Lock,
  GpsFixedOutlined as Target,
 } from "@material-ui/icons/";

const useStyles = makeStyles(() => ({
  root: {},
  textWhite: {
    color: 'white',
  },
  textDark: {
    color: '#0F111B',
  },
}));

const Process = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title={
          <span>
            Our Step-by-Step Process
          </span>
        }
        subtitle="We follow Shuhari (Kanji: 守破離 Hiragana: しゅはり) and pragmatic Agile concepts in our approach. This is how our working together might look:"
        fadeUp
        subtitleProps={{
          className: classes.textWhite,
        }}
      />
      <VerticalTimeline>
          <VerticalTimelineElement
            iconStyle={{ background: 'rgb(165, 172, 208)', color: '#ffffff' }}
            icon={<Chat />}
          />
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            //date="2011 - present"
            iconStyle={{ background: 'rgb(15, 17, 27)', color: '#fff' }}
            icon={<Target />}
          >
            <SectionHeader
              title={
                <span>
                  <span className={classes.textDark}>Evaluate if Machine Learning can help your use case</span>
                  <br />
                </span>
              }
              subtitle={
                <span className={classes.textDark}>
                  <strong>Duration:</strong> 1-3 weeks
                  <br />
                  <p>Together we gather requirements and evaluate if AI can help optimize your processes. Alternatively, if
                  you have a project in mind already, we discuss ways of working together.</p>
                </span>
              }
              align="left"
              data-aos="zoom-in"
              disableGutter
              titleProps={{
                className: clsx(classes.title, classes.textWhite),
                variant: 'h6',
              }}
              subtitleProps={{
                className: classes.textWhite,
                variant: 'h6',
              }}
            />
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            //date="2010 - 2011"
            iconStyle={{ background: 'rgb(15, 17, 27)', color: '#fff' }}
            icon={<BlurOff />}
          >
            <SectionHeader
              title={
                <span>
                  <span className={classes.textDark}>Develop a proof-of-concept</span>
                  <br />
                </span>
              }
              subtitle={
                <span className={classes.textDark}>
                  <strong>Duration:</strong> 4-6 weeks
                  <br />
                  <p>Although no good project gets done overnight, we can build a prototype demonstrating the power of
                  AI relatively fast. You will also get a detailed long-term plan to reap benefits from the AI investment.</p>
                </span>
              }
              align="left"
              data-aos="zoom-in"
              disableGutter
              titleProps={{
                className: clsx(classes.title, classes.textWhite),
                variant: 'h6',
              }}
              subtitleProps={{
                className: classes.textWhite,
                variant: 'h6',
              }}
            />
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            //date="2008 - 2010"
            iconStyle={{ background: 'rgb(15, 17, 27)', color: '#fff' }}
            icon={<DeveloperBoard />}
          >
            <SectionHeader
              title={
                <span>
                  <span className={classes.textDark}>Work iteratively to reach a mature, production-grade stage</span>
                  <br />
                </span>
              }
              subtitle={
                <span className={classes.textDark}>
                  <strong>Duration:</strong> 5+ months
                  <br />
                  <p>After the evaluation phase, we are ready to work together iteratively. We will work over several milestones -
                  together with your team - to refine the system's effectiveness in solving its task.</p>
                </span>
              }
              align="left"
              data-aos="zoom-in"
              disableGutter
              titleProps={{
                className: clsx(classes.title, classes.textWhite),
                variant: 'h6',
              }}
              subtitleProps={{
                className: classes.textWhite,
                variant: 'h6',
              }}
            />
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            //date="2006 - 2008"
            iconStyle={{ background: 'rgb(15, 17, 27)', color: '#fff' }}
            icon={<Lock />}
          >
            <SectionHeader
              title={
                <span>
                  <span className={classes.textDark}>Perform extensive performance and accuracy testing</span>
                  <br />
                </span>
              }
              subtitle={
                <span className={classes.textDark}>
                  <strong>Duration:</strong> As required
                  <br />
                  <p>Machine learning almost always means handling sensitive information. As a last step, we extensively test the
                  application and prepare it for production. We also evaluate corner cases.</p>
                </span>
              }
              align="left"
              data-aos="zoom-in"
              disableGutter
              titleProps={{
                className: clsx(classes.title, classes.textWhite),
                variant: 'h6',
              }}
              subtitleProps={{
                className: classes.textWhite,
                variant: 'h6',
              }}
            />
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: 'rgb(165, 172, 208)', color: '#fff' }}
            icon={<CheckBox />}
          />
        </VerticalTimeline>
    </div>
  );
};

Process.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Process;
