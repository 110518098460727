import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
// import { Image } from 'components/atoms';
// import { SectionHeader } from 'components/molecules';
import { Map, Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  map: {
    zIndex: 9,
    filter: 'grayscale(100%)'
  },
  image: {
    minHeight: 400,
    opacity: '50%',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  textWhite: {
    color: 'white',
  },
  title: {
    fontWeight: 'bold',
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));



const MapSection = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Section className={classes.section}>
        <Map
            center={[43.651070, -79.347015]}
            pins={data}
            className={classes.map}
          />
      </Section>
    </div>
  );
};

MapSection.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default MapSection;
