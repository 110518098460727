import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './common';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

// import async from "./components/async";

//const Home = async(() => import("./views/Home"));

import {
  About,
  Home,
  CareerListing,
  ContactUs,
  DataScience,
  MLEngineering, 
  NotFoundCover,
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      <RouteWithLayout
        component={Home}
        exact
        layout={MainLayout}
        path="/home"
      />
      <RouteWithLayout
        component={About}
        exact
        layout={MainLayout}
        path="/about"
      />
      <RouteWithLayout
        component={CareerListing}
        exact
        layout={MainLayout}
        path="/career"
      />
      <RouteWithLayout
        component={ContactUs}
        exact
        layout={MainLayout}
        path="/contact-us"
      />
      <RouteWithLayout
        component={DataScience}
        exact
        layout={MainLayout}
        path="/data-science"
      />
      <RouteWithLayout
        component={MLEngineering}
        exact
        layout={MainLayout}
        path="/ml-engineering"
      />
      <RouteWithLayout
        component={NotFoundCover}
        exact
        layout={MinimalLayout}
        path="/not-found-cover"
      />
      <Redirect to="/not-found-cover" status="404" />
    </Switch>
  );
};

export default Routes;
