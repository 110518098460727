import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Grid, useMediaQuery } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

import { NavLink as RouterLink } from 'react-router-dom';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <RouterLink {...props} />
  </div>
));

const useStyles = makeStyles(theme => ({
  root: {},
  textWhite: {
    color: 'white',
  },
  textDark: {
    color: '#0F111B',
  },
}));

const Contact = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={isMd ? 4 : 2} data-aos="fade-up">
          <SectionHeader
            title={
              <span>
                <span className={classes.textWhite}>NOT SURE WHERE TO START?</span>
                <br />
                <span className={classes.textDark}> ASK OUR MACHINE LEARNING EXPERTS!</span>{ }
                <br />
              </span>
            }
            subtitle={<span className={classes.textDark}>Together we will analyze your in-house capabilities and 
            identify how to leverage AI/ML to the best advantage of your business.</span>}
            disableGutter
            align="center"
            ctaGroup={[
              <Button variant="contained" color="primary" size="large" component={CustomRouterLink} to={"/contact-us"}>
                Contact
              </Button>
            ]}
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
            data-aos="fade-up"
            data-aos-easing="zoom-out-sine"
            data-aos-duration="2000"
          />
      </Grid>
    </div>
  );
};

Contact.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Contact;
