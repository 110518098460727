import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  root: {},
  textWhite: {
    color: 'white',
  },
  textLightBlue: {
    color: '#A5ACD0',
  },
  textDarkBlue: {
    color: '#0F111B',
  },
}));

const WhoWeAre = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6} data-aos="fade-up">
          <SectionHeader
            title={
              <span>
                <span className={classes.textDarkBlue}>CETERIS </span>
               <span className={classes.textLightBlue}>| PARIBUS</span>
                <br />
              </span>
            }
            subtitle={<span className={classes.textDark}>ce·​te·​ris pa·​ri·​bus | \ ˈkā-tər-əs-ˈpa-rə-bəs  , ˈke-, ˈse-\</span>}
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
          <SectionHeader
            title=""
            subtitle={<span className={classes.textDark}><strong>Definition:</strong> <i>adverb;</i> if all other relevant things, factors, or elements remain
            unaltered; quite literally, <strong>'Everything being equal'</strong>.</span>}
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
          <SectionHeader
            title=""
            subtitle={<span className={classes.textDark}><strong>CETERIS.AI:</strong> <i>noun;</i> a Machine Learning Consultancy experienced in applying Business Analysis, Decision Intelligence,
            and Machine Learning to solve real business problems.</span>}
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} data-aos="fade-up">
          <SectionHeader
            title={<span className={classes.textDark}>What’s up with the name?</span>}
            subtitle={<span className={classes.textDark}>We believe that every problem can be broken down into smaller pieces and those problems can be solved
            through the judicious use of business / data analysis and decision intelligence in conjunction with machine learning.</span>}
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

WhoWeAre.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default WhoWeAre;
