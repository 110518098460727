import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
// import { Button } from '@material-ui/core';
// import { Image } from 'components/atoms';
import { SectionHeader, TypedText } from 'components/molecules';
import { Section } from 'components/organisms';

import {
  Grid,
} from '@material-ui/core';

//import clip from '../../../../assets/video/videoblocks-stock-analyst-and-infographics-illustrating-his-work.mp4';
import clip from '../../../../assets/video/production/videoblocks-stock-analyst-and-infographics-illustrating-his-work_rogmnqa0un_1080__D.mp4';
import Poster from '../../../../assets/video/production/ceteris-ai-logo-dark-outline2.png';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '50%',
    position: 'relative',
    background: '#0F111B',
    overflow: 'hidden',
  },
  image: {
    minHeight: 400,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  textWhite: {
    color: '#FFF',
  },
  textLightBlue: {
    color: '#A5ACD0',
  },
  textDarkBlue: {
    color: '#0F111B',
  },
  typed: {
    // fontWeight: 'bold',
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const Hero = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid item xs={12} md={10}>
        <Grid container spacing={0}>
          <video autoPlay loop muted playsInline poster={Poster} style={{ opacity: '40%' }}>
            <source src={clip} type='video/mp4' />
            <source src={clip} type="video/ogg" />
          </video>
        </Grid>
      </Grid>
      {/* <Image
        // src="/images/photos/about/toronto.png"
        // srcSet="/images/photos/about/toronto.png 2x"
        alt="About"
        className={classes.image}
        lazyProps={{
          width: '100%',
          height: '50%',
        }}
      /> */}
      <Section className={classes.section}>
        <SectionHeader
          title={
            <>
              <span className={classes.textLightBlue}>CETERIS</span><span className={classes.textWhite}>{' '}|{' '}</span><span className={classes.textLightBlue}>AI</span>
              <br />
              <TypedText
                component="span"
                variant="h2"
                color="secondary"
                className={classes.typed}
                typedProps={{
                  strings: [
                    'Strategy',
                    'Business Intelligence',
                    'Data Analysis',
                    'Decision Intelligence',
                    'Data Science',
                    'Machine Learning',
                    'ML Engineering',
                    'Artificial Intelligence',
                  ],
                  typeSpeed: 75,
                  loop: true,
                }}
              />
            </>
          }
          subtitle={<span className={classes.textWhite}>An Enterprise AI solutions firm that powers your business' vision with artificial intelligence and machine learning.</span>}
          align="center"
          titleProps={{
            variant: 'h2',
            color: 'textDark',
          }}
          subtitleProps={{
            color: 'textSecondary',
            variant: 'h4',
          }}
          data-aos="fade-up"
          disableGutter
        />
      </Section>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
