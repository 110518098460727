import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

import Modal from "../Modal";
import Scenario from "../Scenario";

import { 
  useMediaQuery, 
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';

import {
  industries,
} from '../../../../data';

// A scenarios variable of an array of objects -- makes it so we don't have to 
// repeat <Scenario title=".." description="..."src={...} handleOpenModal={..} /> 
// over and over, instead we map over these objects as "props" and spread
// them out inside of "Scenario" like so: <Scenario {...props} />, which 
// would be the same as:  
// <Scenario title={title} description={description} src={src} />

const scenarios = [
  {
    title: "Banking & Capital Markets",
    description: "Banking banking banking.",
    src: '/images/industries/banking-capital-markets.jpg',
  },
  {
    title: "Healthcare",
    description: "Healthcare healthcare healthcare.",
    src: "/images/industries/healthcare.jpg"
  },
  {
    title: "Energy (Oil & Gas)",
    description: "Energy",
    src: "/images/industries/oil-and-gas.jpg"
  },
  {
    title: "Law",
    description: "Legal",
    src: "/images/industries/legal.jpg"
  },
  {
    title: "Retail & Consumer Goods",
    description: "Retail",
    src: "/images/industries/retail.jpg"
  },
  {
    title: "Insurance",
    description: "Insurance",
    src: "/images/industries/lending.jpg"
  },
  {
    title: "Audit & Compliance",
    description: "Audit",
    src: "/images/industries/lending.jpg"
  },
  {
    title: "Real Estate",
    description: "Real Estate",
    src: "/images/industries/buildings.jpg"
  },
  {
    title: "Telecommunications",
    description: "Telecommunications",
    src: "/images/industries/telecoms.jpg"
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },

}));

// Scenarios is a stateful class component that'll act as the parent 
// for its "Scenario" children. The children will update the parent via 
// "this.handleOpenModal". Meanwhile, "Modal" will sit inside the parent
// waiting for the parent state updates to affect how its rendered. The 
// Modal will close itself via the parent's "this.handleCloseModal"
// class method when the "Okay" button is clicked.

class Scenarios extends Component {
  state = { description: "", openModal: false, title: "" };

  handleOpenModal = ({ description, title }) => {
    this.setState({ description, openModal: true, title });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  render = props => (
    <section className="Industry-scenarios">
      <div className={clsx(this.root, this.className)}>
      
        {industries.map(props => (
          <Grid container xs={6} md={10} spacing={2}>
          <Scenario
            {...props}
            key={props.title} // this is required for React to know that each "Scenario" that is returned by the "map" function is unique and must be handled as individual components (otherwise React will complain and throw a warning)
            handleOpenModal={this.handleOpenModal}
            fadeUp
          />
          </Grid>
        ))}
      
      </div>
      <Modal {...this.state} onCloseModal={this.handleCloseModal} />
    </section>
  );
}

export default Scenarios;

// const Scenarios = () => {
//   const classes = useStyles();

//   const handleOpenModal = ({ description, title }) => {
//         this.setState({ description, openModal: true, title });
//       };
    
//   const handleCloseModal = () => {
//         this.setState({ openModal: false });
//       };

//   // const [openBottombar, setOpenBottombar] = React.useState(false);

//   // const handleBottombarOpen = () => {
//   //   setOpenBottombar(true);
//   // };

//   // const handleBottombarClose = () => {
//   //   setOpenBottombar(false);
//   // };

//   return (
//     <section className="Industry-scenarios">
//     <div className={classes.root}>
//       {scenarios.map(props => (
//           <Scenario
//             {...props}
//             key={props.title} // this is required for React to know that each "Scenario" that is returned by the "map" function is unique and must be handled as individual components (otherwise React will complain and throw a warning)
//             handleOpenModal={this.handleOpenModal}
//             fadeUp
//           />
//         ))}
//       </div>
//       <Modal {...this.state} onCloseModal={this.handleCloseModal} />
//     </section>
//   );
// };

// export default Scenarios;