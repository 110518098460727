export const partners = [
  {
    name: 'Airbnb',
    logo: '/images/logos/airbnb.svg',
  },
  {
    name: 'Coinbase',
    logo: '/images/logos/coinbase.svg',
  },
  {
    name: 'Dribbble',
    logo: '/images/logos/dribbble.svg',
  },
  {
    name: 'Instagram',
    logo: '/images/logos/instagram.svg',
  },
  {
    name: 'Netflix',
    logo: '/images/logos/netflix.svg',
  },
  {
    name: 'Pinterest',
    logo: '/images/logos/pinterest.svg',
  },
];

export const advantages = [
  {
    title: 'Data Science',
    description:
      'From strategy, problem identification, and problem scoping to descriptive/diagnostic/predictive/prescriptive analytics, our data scientists are able to rapidly develop AI / machine learning models that provide proven business value to business stakeholders. Experienced in multiple AI specialities including machine learning, deep learning, computer vision, natural language processing and optimization, we connect the dots between business outcomes and AI.',
    icon: 'fa fa-flask',
    link: '/data-science',
  },
  {
    title: 'ML Engineering',
    description:
      'As data continues to grow exponentially, your data solutions need to scale accordingly. Getting your data science initiatives beyond the PoC, and "corporate science experiments", requires engineering skills and discipline. Our ML Engineers are able to help your organization rapidly adopt and scale up your ML capabilities through the use of scalable Big Data technologies like Elastic Stack (formerly ELK stack) and Spark.',
    icon: 'fa fa-cogs',
    link: '/ml-engineering',
  },
];

export const reviews = [
  {
    authorPhoto: {
      src: '/images/photos/people/veronica-adams.jpg',
      srcSet: '/images/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: '/images/photos/people/akachi-luccini.jpg',
      srcSet: '/images/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    authorPhoto: {
      src: '/images/photos/people/jack-smith.jpg',
      srcSet: '/images/photos/people/jack-smith@2x.jpg 2x',
    },
    authorName: 'Jack Smith',
    authorOccupation: 'Head of Operations, Parkfield Commerce',
    feedback:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
];

export const integrations = [
  {
    logo: '/images/logos/slack.svg',
    name: 'Slack',
    title:
      "Sync your team's work and activity to share automatically in a channel with a simple plugin.",
  },
  {
    logo: '/images/logos/mailchimp.svg',
    name: 'Mailchimp',
    title:
      'Communicate important messages to your users through TheFront using Mailchimp as the delivery service.',
  },
  {
    logo: '/images/logos/dropbox.svg',
    name: 'Dropbox',
    title:
      'Sync any file store to Dropbox for automated sharing with people outside the company.',
  },
  {
    logo: '/images/logos/google-drive.svg',
    name: 'Google Drive',
    title:
      'Sync any file store to Google Drive for automated sharing with people outside the company.',
  },
  {
    logo: '/images/logos/google-ad-manager.svg',
    name: 'Google Ad Manager',
    title:
      'Easily manage and edit any Adwords campaign inline to improve ROI with constant review.',
  },
  {
    logo: '/images/logos/atlassian.svg',
    name: 'Atlassian',
    title:
      'Keep your entire team in sync with development and easily manage tasks, goals, and deadlines.',
  },
];

export const customizations = [
  {
    title: '3rd-Party Integrations',
    icon: 'fas fa-plug',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    title: 'Meets Privacy Requirements',
    description:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    icon: 'fas fa-lock',
  },
  {
    title: 'Marketing & Sales Collateral',
    description:
      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    icon: 'fas fa-hand-holding-heart',
  },
  {
    title: 'Integrates With Most Hardware',
    description:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    icon: 'fas fa-plus',
  },
];

export const team = [
  {
    title: 'UI/UX Designer',
    authorPhoto: {
      src: '/images/photos/people/kate-segelson.jpg',
      srcSet: '/images/photos/people/kate-segelson@2x.jpg 2x',
    },
    authorName: 'Kate Segelson',
  },
  {
    title: 'Web Backend Developer',
    authorPhoto: {
      src: '/images/photos/people/alex-johnson.jpg',
      srcSet: '/images/photos/people/alex-johnson@2x.jpg 2x',
    },
    authorName: 'Alex Johnson',
  },
  {
    title: 'Web Frontend Developer',
    authorPhoto: {
      src: '/images/photos/people/valeria-kogan.jpg',
      srcSet: '/images/photos/people/valeria-kogan@2x.jpg 2x',
    },
    authorName: 'Valeria Kogan',
  },
];
