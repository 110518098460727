import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// import { colors, Divider } from '@material-ui/core';

import { SectionAlternate } from 'components/organisms';

import {
  Hero,
  Working,
  Careers,
  Contact,
} from './components';

import { faq, jobs_new } from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sectionAlternate: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, #FFFFFF 400px, #A5ACD0 0%)`,
  },
  sectionFAQ: {
    background: theme.palette.primary.light,
  },
  sectionContact: {
    background: theme.palette.primary.light,
  },
}));

const CareerListing = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hero />
      {/* <SectionAlternate>
        <Promo data={technologies} />
      </SectionAlternate> */}
      <SectionAlternate className={classes.sectionAlternate}>
        <Working data={faq} />
      </SectionAlternate>
      {/* <Section>
        <Process />
      </Section> */}
      {/* <Divider /> */}
      {/* <Section>
        <Technologies data={jobCategories} />
      </Section> */}
      {/* <SectionAlternate>
        <Info />
      </SectionAlternate> */}
      {/* <Section>
        <Jobs data={jobs} />
      </Section>
      <SectionAlternate>
        <Placements />
      </SectionAlternate> */}
      <SectionAlternate>
        <Careers data={jobs_new} />
      </SectionAlternate>
      <SectionAlternate className={classes.sectionNoPaddingTop, classes.sectionContact}>
        <Contact id="contact" />
      </SectionAlternate>
      {/* <SectionAlternate>
        <Application />
      </SectionAlternate> */}
    </div>
  );
};

export default withRouter(CareerListing);
