import React, { forwardRef, useState }  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { NavLink as RouterLink } from 'react-router-dom';

import { Scenarios } from './components';

import { 
  useMediaQuery, 
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';

import { LearnMoreLink, Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardProduct } from 'components/organisms';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <RouterLink {...props} />
  </div>
));

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeight700: {
    fontWeight: 700,
  },
  coverImage: {
    objectFit: 'cover',
  },
  textWhite: {
    color: '#FFF',
  },
  textLightBlue: {
    color: '#A5ACD0',
  },
  textDarkBlue: {
    color: '#0F111B',
  },
}));

const Industries = props => {
  const { data, className, ...rest } = props;


  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openBanking, setOpenBanking] = React.useState(false);
  const [openHealthcare, setOpenHealthcare] = React.useState(false);
  const [openEnergy, setOpenEnergy] = React.useState(false);
  const [openLaw, setOpenLaw] = React.useState(false);
  const [openRetail, setOpenRetail] = React.useState(false);
  const [openInsurance, setOpenInsurance] = React.useState(false);
  const [openAudit, setOpenAudit] = React.useState(false);
  const [openRealEstate, setOpenRealEstate] = React.useState(false);
  const [openTelecoms, setOpenTelecoms] = React.useState(false);

  const handleClickOpenBanking = () => {
    setOpenBanking(true);
  };

  const handleClickOpenHealthcare = () => {
    setOpenHealthcare(true);
  };

  const handleClickOpenEnergy = () => {
    setOpenEnergy(true);
  };

  const handleClickOpenLaw = () => {
    setOpenLaw(true);
  };

  const handleClickOpenRetail = () => {
    setOpenRetail(true);
  };

  const handleClickOpenInsurance = () => {
    setOpenInsurance(true);
  };

  const handleClickOpenAudit = () => {
    setOpenAudit(true);
  };

  const handleClickOpenRealEstate = () => {
    setOpenRealEstate(true);
  };

  const handleClickOpenTelecoms = () => {
    setOpenTelecoms(true);
  };

  const handleCloseBanking = () => {
    setOpenBanking(false);
  };

  const handleCloseHealthcare = () => {
    setOpenHealthcare(false);
  };

  const handleCloseEnergy = () => {
    setOpenEnergy(false);
  };

  const handleCloseLaw = () => {
    setOpenLaw(false);
  };

  const handleCloseRetail = () => {
    setOpenRetail(false);
  };

  const handleCloseInsurance = () => {
    setOpenInsurance(false);
  };

  const handleCloseAudit = () => {
    setOpenAudit(false);
  };

  const handleCloseRealEstate = () => {
    setOpenRealEstate(false);
  };

  const handleCloseTelecoms = () => {
    setOpenTelecoms(false);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title={
          <span><span className={classes.textDarkBlue}>CETERIS</span><span className={classes.textLightBlue}>{' '}|{' '}</span><span className={classes.textDarkBlue}>AI</span>
          <span className={classes.textLightBlue}>{' '}provides{' '}
            <span className="text-highlighted">
            Tailor-made cutting-edge ML solutions for your Industry & Business.
            </span>
          </span></span>
        }
        subtitle={<span>See sample AI applications by clicking on each industry.</span>}
        fadeUp
      />
      <Grid container spacing={isMd ? 4 : 2}>
      {/* {data.map((item, index) => (
          <Grid key={index} item xs={12} md={4} data-aos={'fade-up'}>
            <CardProduct
              withShadow
              liftUp
              mediaContent={
                <Image
                  className={classes.coverImage}
                  {...item.cover}
                  alt={item.title}
                  lazyProps={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              cardContent={
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      align="left"
                      className={classes.fontWeight700}
                    >
                      {item.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LearnMoreLink title={'Learn more'} variant="body1" component={CustomRouterLink}  to={index.link}/>
                    
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        ))} */}
          <Grid item xs={12} md={4} data-aos={'fade-up'}>
            <CardProduct
              withShadow
              liftUp
              mediaContent={
                <Image
                  className={classes.coverImage}
                  src={data[0].cover.src}
                  srcSet={data[0].cover.srcSet}
                  alt={data[0].title}
                  lazyProps={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              cardContent={
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      align="left"
                      className={classes.fontWeight700}
                    >
                      {data[0].title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LearnMoreLink title={'Learn more'} variant="body1" onClick={handleClickOpenBanking} />
                    <Dialog
                      open={openBanking}
                      onClose={handleCloseBanking}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title" align="center">
                        <Image
                          className={classes.coverImage}
                          src={data[0].cover.src}
                          srcSet={data[0].cover.srcSet}
                          alt={data[0].title}
                          lazyProps={{
                            width: '50%',
                            height: '20%',
                            align: 'center',
                          }}
                        />
                        <br />
                        {data[0].title}
                        <Divider />
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <strong><u>Examples of AI-based tools include but not limited to the following:</u></strong>
                        <br /><br />
                        <p>
                          <ul>
                            <li><strong>{data[0].usecases[0].type}</strong>{' '}{data[0].usecases[0].description}</li>
                            <li><strong>{data[0].usecases[1].type}</strong>{' '}{data[0].usecases[1].description}</li>
                            <li><strong>{data[0].usecases[2].type}</strong>{' '}{data[0].usecases[2].description}</li>
                            <li><strong>{data[0].usecases[3].type}</strong>{' '}{data[0].usecases[3].description}</li>
                            <li><strong>{data[0].usecases[4].type}</strong>{' '}{data[0].usecases[4].description}</li>
                            <li><strong>{data[0].usecases[5].type}</strong>{' '}{data[0].usecases[5].description}</li>
                            <li><strong>{data[0].usecases[6].type}</strong>{' '}{data[0].usecases[6].description}</li>
                            <li><strong>{data[0].usecases[7].type}</strong>{' '}{data[0].usecases[7].description}</li>
                            <li><strong>{data[0].usecases[8].type}</strong>{' '}{data[0].usecases[8].description}</li>
                            <li><strong>{data[0].usecases[9].type}</strong>{' '}{data[0].usecases[9].description}</li>
                            <li><strong>{data[0].usecases[10].type}</strong>{' '}{data[0].usecases[10].description}</li>
                            <li><strong>{data[0].usecases[11].type}</strong>{' '}{data[0].usecases[11].description}</li>
                            <li><strong>{data[0].usecases[12].type}</strong>{' '}{data[0].usecases[12].description}</li>
                            <li><strong>{data[0].usecases[13].type}</strong>{' '}{data[0].usecases[13].description}</li>
                            <li><strong>{data[0].usecases[14].type}</strong>{' '}{data[0].usecases[14].description}</li>
                            <li><strong>{data[0].usecases[15].type}</strong>{' '}{data[0].usecases[15].description}</li>
                            <li><strong>{data[0].usecases[16].type}</strong>{' '}{data[0].usecases[16].description}</li>
                            <li><strong>{data[0].usecases[17].type}</strong>{' '}{data[0].usecases[17].description}</li>
                            <li><strong>{data[0].usecases[18].type}</strong>{' '}{data[0].usecases[18].description}</li>
                          </ul>
                        </p>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseBanking} color="primary" autoFocus>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12} md={4} data-aos={'fade-up'}>
            <CardProduct
              withShadow
              liftUp
              mediaContent={
                <Image
                  className={classes.coverImage}
                  src={data[1].cover.src}
                  srcSet={data[1].cover.srcSet}
                  alt={data[1].title}
                  lazyProps={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              cardContent={
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      align="left"
                      className={classes.fontWeight700}
                    >
                      {data[1].title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LearnMoreLink title={'Learn more'} variant="body1" onClick={handleClickOpenHealthcare} />
                    <Dialog
                      open={openHealthcare}
                      onClose={handleCloseHealthcare}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title" align="center">
                        <Image
                          className={classes.coverImage}
                          src={data[1].cover.src}
                          srcSet={data[1].cover.srcSet}
                          alt={data[1].title}
                          lazyProps={{
                            width: '50%',
                            height: '20%',
                            align: 'center',
                          }}
                        />
                        <br />
                        {data[1].title}
                        <Divider />
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <strong><u>Examples of AI-based tools include but not limited to the following:</u></strong>
                        <br /><br />
                        <p>
                          <ul>
                            <li><strong>{data[1].usecases[0].type}</strong>{' '}{data[1].usecases[0].description}</li>
                            <li><strong>{data[1].usecases[1].type}</strong>{' '}{data[1].usecases[1].description}</li>
                            <li><strong>{data[1].usecases[2].type}</strong>{' '}{data[1].usecases[2].description}</li>
                            <li><strong>{data[1].usecases[3].type}</strong>{' '}{data[1].usecases[3].description}</li>
                            <li><strong>{data[1].usecases[4].type}</strong>{' '}{data[1].usecases[4].description}</li>
                            <li><strong>{data[1].usecases[5].type}</strong>{' '}{data[1].usecases[5].description}</li>
                            <li><strong>{data[1].usecases[6].type}</strong>{' '}{data[1].usecases[6].description}</li>
                            <li><strong>{data[1].usecases[7].type}</strong>{' '}{data[1].usecases[7].description}</li>
                            <li><strong>{data[1].usecases[8].type}</strong>{' '}{data[1].usecases[8].description}</li>
                            <li><strong>{data[1].usecases[9].type}</strong>{' '}{data[1].usecases[9].description}</li>
                            <li><strong>{data[1].usecases[10].type}</strong>{' '}{data[1].usecases[10].description}</li>
                          </ul>
                        </p>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseHealthcare} color="primary" autoFocus>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12} md={4} data-aos={'fade-up'}>
            <CardProduct
              withShadow
              liftUp
              mediaContent={
                <Image
                  className={classes.coverImage}
                  src={data[2].cover.src}
                  srcSet={data[2].cover.srcSet}
                  alt={data[2].title}
                  lazyProps={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              cardContent={
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      align="left"
                      className={classes.fontWeight700}
                    >
                      {data[2].title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LearnMoreLink title={'Learn more'} variant="body1" onClick={handleClickOpenEnergy} />
                    <Dialog
                      open={openEnergy}
                      onClose={handleCloseEnergy}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title" align="center">
                        <Image
                          className={classes.coverImage}
                          src={data[2].cover.src}
                          srcSet={data[2].cover.srcSet}
                          alt={data[2].title}
                          lazyProps={{
                            width: '50%',
                            height: '20%',
                            align: 'center',
                          }}
                        />
                        <br />
                        {data[2].title}
                        <Divider />
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <strong><u>Examples of AI-based tools include but not limited to the following:</u></strong>
                        <br /><br />
                        <p>
                          <ul>
                            <li><strong>{data[2].usecases[0].type}</strong>{' '}{data[2].usecases[0].description}</li>
                            <li><strong>{data[2].usecases[1].type}</strong>{' '}{data[2].usecases[1].description}</li>
                            <li><strong>{data[2].usecases[2].type}</strong>{' '}{data[2].usecases[2].description}</li>
                            <li><strong>{data[2].usecases[3].type}</strong>{' '}{data[2].usecases[3].description}</li>
                            <li><strong>{data[2].usecases[4].type}</strong>{' '}{data[2].usecases[4].description}</li>
                            <li><strong>{data[2].usecases[5].type}</strong>{' '}{data[2].usecases[5].description}</li>
                            <li><strong>{data[2].usecases[6].type}</strong>{' '}{data[2].usecases[6].description}</li>
                          </ul>
                        </p>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseEnergy} color="primary" autoFocus>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12} md={4} data-aos={'fade-up'}>
            <CardProduct
              withShadow
              liftUp
              mediaContent={
                <Image
                  className={classes.coverImage}
                  src={data[3].cover.src}
                  srcSet={data[3].cover.srcSet}
                  alt={data[3].title}
                  lazyProps={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              cardContent={
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      align="left"
                      className={classes.fontWeight700}
                    >
                      {data[3].title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LearnMoreLink title={'Learn more'} variant="body1" onClick={handleClickOpenLaw} />
                    <Dialog
                      open={openLaw}
                      onClose={handleCloseLaw}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title" align="center">
                        <Image
                          className={classes.coverImage}
                          src={data[3].cover.src}
                          srcSet={data[3].cover.srcSet}
                          alt={data[3].title}
                          lazyProps={{
                            width: '50%',
                            height: '20%',
                            align: 'center',
                          }}
                        />
                        <br />
                        {data[3].title}
                        <Divider />
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <strong><u>Examples of AI-based tools include but not limited to the following:</u></strong>
                        <br /><br />
                        <p>
                          <ul>
                            <li><strong>{data[3].usecases[0].type}</strong>{' '}{data[3].usecases[0].description}</li>
                            <li><strong>{data[3].usecases[1].type}</strong>{' '}{data[3].usecases[1].description}</li>
                            <li><strong>{data[3].usecases[2].type}</strong>{' '}{data[3].usecases[2].description}</li>
                            <li><strong>{data[3].usecases[3].type}</strong>{' '}{data[3].usecases[3].description}</li>
                            <li><strong>{data[3].usecases[4].type}</strong>{' '}{data[3].usecases[4].description}</li>
                            <li><strong>{data[3].usecases[5].type}</strong>{' '}{data[3].usecases[5].description}</li>
                            <li><strong>{data[3].usecases[6].type}</strong>{' '}{data[3].usecases[6].description}</li>
                            <li><strong>{data[3].usecases[7].type}</strong>{' '}{data[3].usecases[7].description}</li>
                            <li><strong>{data[3].usecases[8].type}</strong>{' '}{data[3].usecases[8].description}</li>
                            <li><strong>{data[3].usecases[9].type}</strong>{' '}{data[3].usecases[9].description}</li>
                            <li><strong>{data[3].usecases[10].type}</strong>{' '}{data[3].usecases[10].description}</li>
                          </ul>
                        </p>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseLaw} color="primary" autoFocus>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12} md={4} data-aos={'fade-up'}>
            <CardProduct
              withShadow
              liftUp
              mediaContent={
                <Image
                  className={classes.coverImage}
                  src={data[4].cover.src}
                  srcSet={data[4].cover.srcSet}
                  alt={data[4].title}
                  lazyProps={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              cardContent={
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      align="left"
                      className={classes.fontWeight700}
                    >
                      {data[4].title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LearnMoreLink title={'Learn more'} variant="body1" onClick={handleClickOpenRetail} />
                    <Dialog
                      open={openRetail}
                      onClose={handleCloseRetail}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title" align="center">
                        <Image
                          className={classes.coverImage}
                          src={data[4].cover.src}
                          srcSet={data[4].cover.srcSet}
                          alt={data[4].title}
                          lazyProps={{
                            width: '50%',
                            height: '20%',
                            align: 'center',
                          }}
                        />
                        <br />
                        {data[4].title}
                        <Divider />
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <strong><u>Examples of AI-based tools include but not limited to the following:</u></strong>
                        <br /><br />
                        <p>
                          <ul>
                            <li><strong>{data[4].usecases[0].type}</strong>{' '}{data[4].usecases[0].description}</li>
                            <li><strong>{data[4].usecases[1].type}</strong>{' '}{data[4].usecases[1].description}</li>
                            <li><strong>{data[4].usecases[2].type}</strong>{' '}{data[4].usecases[2].description}</li>
                            <li><strong>{data[4].usecases[3].type}</strong>{' '}{data[4].usecases[3].description}</li>
                            <li><strong>{data[4].usecases[4].type}</strong>{' '}{data[4].usecases[4].description}</li>
                            <li><strong>{data[4].usecases[5].type}</strong>{' '}{data[4].usecases[5].description}</li>
                            <li><strong>{data[4].usecases[6].type}</strong>{' '}{data[4].usecases[6].description}</li>
                            <li><strong>{data[4].usecases[7].type}</strong>{' '}{data[4].usecases[7].description}</li>
                            <li><strong>{data[4].usecases[8].type}</strong>{' '}{data[4].usecases[8].description}</li>
                            <li><strong>{data[4].usecases[9].type}</strong>{' '}{data[4].usecases[9].description}</li>
                            <li><strong>{data[4].usecases[10].type}</strong>{' '}{data[4].usecases[10].description}</li>
                          </ul>
                        </p>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseRetail} color="primary" autoFocus>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12} md={4} data-aos={'fade-up'}>
            <CardProduct
              withShadow
              liftUp
              mediaContent={
                <Image
                  className={classes.coverImage}
                  src={data[5].cover.src}
                  srcSet={data[5].cover.srcSet}
                  alt={data[5].title}
                  lazyProps={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              cardContent={
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      align="left"
                      className={classes.fontWeight700}
                    >
                      {data[5].title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LearnMoreLink title={'Learn more'} variant="body1" onClick={handleClickOpenInsurance} />
                    <Dialog
                      open={openInsurance}
                      onClose={handleCloseInsurance}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title" align="center">
                        <Image
                          className={classes.coverImage}
                          src={data[5].cover.src}
                          srcSet={data[5].cover.srcSet}
                          alt={data[5].title}
                          lazyProps={{
                            width: '50%',
                            height: '20%',
                            align: 'center',
                          }}
                        />
                        <br />
                        {data[5].title}
                        <Divider />
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <strong><u>Examples of AI-based tools include but not limited to the following:</u></strong>
                        <br /><br />
                        <p>
                          <ul>
                            <li><strong>{data[5].usecases[0].type}</strong>{' '}{data[5].usecases[0].description}</li>
                            <li><strong>{data[5].usecases[1].type}</strong>{' '}{data[5].usecases[1].description}</li>
                            <li><strong>{data[5].usecases[2].type}</strong>{' '}{data[5].usecases[2].description}</li>
                            <li><strong>{data[5].usecases[3].type}</strong>{' '}{data[5].usecases[3].description}</li>
                            <li><strong>{data[5].usecases[4].type}</strong>{' '}{data[5].usecases[4].description}</li>
                            <li><strong>{data[5].usecases[5].type}</strong>{' '}{data[5].usecases[5].description}</li>
                            <li><strong>{data[5].usecases[6].type}</strong>{' '}{data[5].usecases[6].description}</li>
                          </ul>
                        </p>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseInsurance} color="primary" autoFocus>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12} md={4} data-aos={'fade-up'}>
            <CardProduct
              withShadow
              liftUp
              mediaContent={
                <Image
                  className={classes.coverImage}
                  src={data[6].cover.src}
                  srcSet={data[6].cover.srcSet}
                  alt={data[6].title}
                  lazyProps={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              cardContent={
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      align="left"
                      className={classes.fontWeight700}
                    >
                      {data[6].title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LearnMoreLink title={'Learn more'} variant="body1" onClick={handleClickOpenAudit} />
                    <Dialog
                      open={openAudit}
                      onClose={handleCloseAudit}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title" align="center">
                        <Image
                          className={classes.coverImage}
                          src={data[6].cover.src}
                          srcSet={data[6].cover.srcSet}
                          alt={data[6].title}
                          lazyProps={{
                            width: '50%',
                            height: '20%',
                            align: 'center',
                          }}
                        />
                        <br />
                        {data[6].title}
                        <Divider />
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <strong><u>Examples of AI-based tools include but not limited to the following:</u></strong>
                        <br /><br />
                        <p>
                          <ul>
                            <li><strong>{data[6].usecases[0].type}</strong>{' '}{data[6].usecases[0].description}</li>
                            <li><strong>{data[6].usecases[1].type}</strong>{' '}{data[6].usecases[1].description}</li>
                            <li><strong>{data[6].usecases[2].type}</strong>{' '}{data[6].usecases[2].description}</li>
                            <li><strong>{data[6].usecases[3].type}</strong>{' '}{data[6].usecases[3].description}</li>
                            <li><strong>{data[6].usecases[4].type}</strong>{' '}{data[6].usecases[4].description}</li>
                            <li><strong>{data[6].usecases[5].type}</strong>{' '}{data[6].usecases[5].description}</li>
                            <li><strong>{data[6].usecases[6].type}</strong>{' '}{data[6].usecases[6].description}</li>
                            <li><strong>{data[6].usecases[7].type}</strong>{' '}{data[6].usecases[7].description}</li>
                            <li><strong>{data[6].usecases[8].type}</strong>{' '}{data[6].usecases[8].description}</li>
                            <li><strong>{data[6].usecases[9].type}</strong>{' '}{data[6].usecases[9].description}</li>
                            <li><strong>{data[6].usecases[10].type}</strong>{' '}{data[6].usecases[10].description}</li>
                          </ul>
                        </p>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseAudit} color="primary" autoFocus>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12} md={4} data-aos={'fade-up'}>
            <CardProduct
              withShadow
              liftUp
              mediaContent={
                <Image
                  className={classes.coverImage}
                  src={data[7].cover.src}
                  srcSet={data[7].cover.srcSet}
                  alt={data[7].title}
                  lazyProps={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              cardContent={
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      align="left"
                      className={classes.fontWeight700}
                    >
                      {data[7].title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LearnMoreLink title={'Learn more'} variant="body1" onClick={handleClickOpenRealEstate} />
                    <Dialog
                      open={openRealEstate}
                      onClose={handleCloseRealEstate}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title" align="center">
                        <Image
                          className={classes.coverImage}
                          src={data[7].cover.src}
                          srcSet={data[7].cover.srcSet}
                          alt={data[7].title}
                          lazyProps={{
                            width: '50%',
                            height: '20%',
                            align: 'center',
                          }}
                        />
                        <br />
                        {data[7].title}
                        <Divider />
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <strong><u>Examples of AI-based tools include but not limited to the following:</u></strong>
                        <br /><br />
                        <p>
                          <ul>
                            <li><strong>{data[7].usecases[0].type}</strong>{' '}{data[7].usecases[0].description}</li>
                            <li><strong>{data[7].usecases[1].type}</strong>{' '}{data[7].usecases[1].description}</li>
                            <li><strong>{data[7].usecases[2].type}</strong>{' '}{data[7].usecases[2].description}</li>
                            <li><strong>{data[7].usecases[3].type}</strong>{' '}{data[7].usecases[3].description}</li>
                            <li><strong>{data[7].usecases[4].type}</strong>{' '}{data[7].usecases[4].description}</li>
                            <li><strong>{data[7].usecases[5].type}</strong>{' '}{data[7].usecases[5].description}</li>
                            <li><strong>{data[7].usecases[6].type}</strong>{' '}{data[7].usecases[6].description}</li>
                            <li><strong>{data[7].usecases[7].type}</strong>{' '}{data[7].usecases[7].description}</li>
                            <li><strong>{data[7].usecases[8].type}</strong>{' '}{data[7].usecases[8].description}</li>
                          </ul>
                        </p>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseRealEstate} color="primary" autoFocus>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12} md={4} data-aos={'fade-up'}>
            <CardProduct
              withShadow
              liftUp
              mediaContent={
                <Image
                  className={classes.coverImage}
                  src={data[8].cover.src}
                  srcSet={data[8].cover.srcSet}
                  alt={data[8].title}
                  lazyProps={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              cardContent={
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      align="left"
                      className={classes.fontWeight700}
                    >
                      {data[8].title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LearnMoreLink title={'Learn more'} variant="body1" onClick={handleClickOpenTelecoms} />
                    <Dialog
                      open={openTelecoms}
                      onClose={handleCloseTelecoms}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title" align="center">
                        <Image
                          className={classes.coverImage}
                          src={data[8].cover.src}
                          srcSet={data[8].cover.srcSet}
                          alt={data[8].title}
                          lazyProps={{
                            width: '50%',
                            height: '20%',
                            align: 'center',
                          }}
                        />
                        <br />
                        {data[8].title}
                        <Divider />
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <strong><u>Examples of AI-based tools include but not limited to the following:</u></strong>
                        <br /><br />
                        <p>
                        `<ul>
                            <li><strong>{data[8].usecases[0].type}</strong>{' '}{data[8].usecases[0].description}</li>
                            <li><strong>{data[8].usecases[1].type}</strong>{' '}{data[8].usecases[1].description}</li>
                            <li><strong>{data[8].usecases[2].type}</strong>{' '}{data[8].usecases[2].description}</li>
                            <li><strong>{data[8].usecases[3].type}</strong>{' '}{data[8].usecases[3].description}</li>
                            <li><strong>{data[8].usecases[4].type}</strong>{' '}{data[8].usecases[4].description}</li>
                            <li><strong>{data[8].usecases[5].type}</strong>{' '}{data[8].usecases[5].description}</li>
                            <li><strong>{data[8].usecases[6].type}</strong>{' '}{data[8].usecases[6].description}</li>
                            <li><strong>{data[8].usecases[7].type}</strong>{' '}{data[8].usecases[7].description}</li>
                            <li><strong>{data[8].usecases[8].type}</strong>{' '}{data[8].usecases[8].description}</li>
                            <li><strong>{data[8].usecases[9].type}</strong>{' '}{data[8].usecases[9].description}</li>
                            <li><strong>{data[8].usecases[10].type}</strong>{' '}{data[8].usecases[10].description}</li>
                          </ul>
                        </p>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseTelecoms} color="primary" autoFocus>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
      </Grid>
    </div>
  );
};

Industries.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Industries;
