import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
// import { LearnMoreLink } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

import {
  Avatar,
  IconButton,
  Link,
  Grid,
} from '@material-ui/core';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

const useStyles = makeStyles(theme => ({
  root: {},
  teamAvatar: {
    width: 250,
    height: 250,
    border: `${theme.spacing(1)}px solid white`,
    boxShadow: '0 2px 10px 0 rgba(23,70,161,.11)',
    marginTop: theme.spacing(1 / 2),
  },
  listGrid: {
    overflow: 'hidden',
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
  textWhite: {
    color: 'white',
  },
  textDarkBlue: {
    color: '#0F111B',
  },
  textLightBlue: {
    color: '#A5ACD0',
  },
}));

const Leadership = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title={
          <span className={classes.textLightBlue}>
            We’re focused on{' '}
            <span className={classes.textDarkBlue}>Your Success</span>
          </span>
        }
        subtitle={<span>Our leadership team are passionate problem solvers with decades of experience in strategy,
        analytics, and transforming businesses across multiple industries. The team is comprised of specialized
        experts with hands-on experience in facilitating successful Artificial Intelligence (AI) transformations.</span>}
        fadeUp
      />
      <Grid container justify="center">
        {data.map((item, index) => (
          <Grid
            key={index}
            item
            container
            data-aos={'fade-up'}
            justify={isMd ? 'space-between' : 'center'}
            spacing={isMd ? 4 : 2}
            className={classes.listGrid}
            direction={index % 2 === 1 ? 'row-reverse' : 'row'}
          >
            <Grid item container xs={12} sm={7} alignItems="center">
              <SectionHeader
                label={item.authorName}
                titleVariant="h5"
                title={item.title}
                subtitle={item.description}
                ctaGroup={[
                  <IconButton
                   component={Link}
                    className={classes.socialIcon}
                    href="https://www.linkedin.com/in/nealgilmore/"
                    target="_blank"
                  >
                    <LinkedInIcon className={classes.icon} />
                  </IconButton>,
                  <IconButton 
                    component={Link}
                    className={classes.socialIcon}
                    href="https://github.com/ai-portfolio"
                    target="_blank"
                  >
                    <GitHubIcon className={classes.icon} />
                  </IconButton>,
                ]}
                align={isMd ? 'left' : 'center'}
                disableGutter
              />
            </Grid>
            <Grid item container justify="center" xs={12} sm={5}>
              <Avatar
                {...item.authorPhoto}
                alt={item.authorName}
                className={classes.teamAvatar}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Leadership.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Leadership;
