import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  IconButton,
  Grid,
  List,
  ListItem,
} from '@material-ui/core';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

import { Image } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 0),
    },
    background: '#0F111B',
  },
  footerContainer: {
    maxWidth: 1100,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
  },
  logoContainerItem: {
    paddingTop: 0,
  },
  logoContainer: {
    width: 120,
    height: 32,
  },
  logoImage: {
    width: '130%',
    height: '130%',
  },
  groupTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(1),
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: 'rgba(255,255,255,.6)',
    '&:hover': {
      background: 'transparent',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 24,
  },
  menuListContainer: {
    padding: '0 !important',
  },
  menu: {
    display: 'flex',
  },
  menuItem: {
    margin: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1 / 2),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
    color: 'white',
  },
  divider: {
    width: '100%',
  },
  navLink: {
    color: 'rgba(255,255,255,.6)',
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const Footer = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  const companyPages = pages.company;
  const servicesPages = pages.services;

  const MenuGroup = props => {
    const { item } = props;
    return (
      <List disablePadding className={classes.menuItem}>
        <ListItem disableGutters className={classes.menuGroupItem}>
          <Typography variant="body2" className={classes.menuGroupTitle}>
            {item.groupTitle}
          </Typography>
        </ListItem>
        {item.pages.map((page, i) => (
          <ListItem disableGutters key={i} className={classes.menuGroupItem}>
            <Typography
              variant="body2"
              component={CustomRouterLink}
              to={page.href}
              className={clsx(classes.navLink, 'submenu-item')}
            >
              {page.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  const CompanyPages = () => {
    const {
      career,
      // helpCenter,
      company,
      contact,
      // blog,
    } = companyPages.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={company} />
          <MenuGroup item={career} />
        </div>
        <div className={classes.menuItem}>
          <MenuGroup item={contact} />
          {/* <MenuGroup item={helpCenter} /> */}
        </div>
        {/* <div className={classes.menuItem}>
          <MenuGroup item={blog} />
        </div> */}
      </div>
    );
  };

  const ServicesPages = () => {
    const {
      data_science,
      ml_engineering,
    } = servicesPages.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={data_science} />
        </div>
        <div className={classes.menuItem}>
          <MenuGroup item={ml_engineering} />
        </div>
      </div>
    );
  };

  const SocialPages = () => {
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <div className={classes.logoContainer}>
            <a href="/" title="CETERIS.AI">
              <Image
                className={classes.logoImage}
                src="/images/logos/ceteris-ai-text-logo-light-outline-2.svg"
                alt="CETERIS.AI"
                lazy={false}
              />
            </a>
          </div>
        <div className={classes.menuItem}>
          <IconButton
            className={classes.socialIcon}
            href="https://www.linkedin.com/company/ceteris-ai"
            target="_blank"
          >
            <LinkedInIcon className={classes.icon} />
          </IconButton>
          {/* <IconButton
            className={classes.socialIcon}
            href="https://github.com/ceteris-ai"
            target="_blank"
          >
            <GitHubIcon className={classes.icon} />
          </IconButton> */}
        </div>
        </div>
      </div>
    );
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.footerContainer}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} className={classes.menuListContainer}>
            <Grid container spacing={0}>
              <Grid item className={classes.listItem}>
                <CompanyPages />
              </Grid>
              <Grid item className={classes.listItem}>
                <ServicesPages />
              </Grid>
              <Grid item className={classes.listItem}>
                <SocialPages />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
};

export default Footer;
