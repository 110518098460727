import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// import {
//   AppBar,
//   Toolbar,
//   Drawer,
//   IconButton,
//   Divider,
// } from '@material-ui/core';
// import ForumIcon from '@material-ui/icons/Forum';
import { Section, SectionAlternate } from 'components/organisms';
import {
  Advantages,
  Contact,
  FocusAreas,
  Hero,
  Industries,
  Technologies,
} from './components';

import {
  advantages,
  industries,
  technologies,

} from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  appBarBottom: {
    top: 'auto',
    bottom: 0,
    background: 'transparent',
    boxShadow: 'none',
  },
  toolbarBottom: {
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
  },
  chatIconButton: {
    position: 'absolute',
    right: theme.spacing(3),
    left: 'auto',
    top: theme.spacing(-3),
    background: theme.palette.primary.main,
    width: 55,
    height: 55,
    boxShadow: '0 2px 10px 0 rgba(23,70,161,.11)',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  forumIcon: {
    color: 'white',
    width: 25,
    height: 25,
  },
  AdvantagesSection: {
    background: theme.palette.primary.white,
  },
  LeadershipSection: {
    background: theme.palette.primary.light,
  },
  FocusAreasSection: {
    background: theme.palette.primary.white,
  },
  TechnologiesSection: {
    background: theme.palette.primary.white,
  },
  IndustriesSection: {
    background: theme.palette.primary.light,
  },
  ContactSection: {
    background: theme.palette.primary.light,
  },
}));

const DataScience = () => {
  const classes = useStyles();

  // const [openBottombar, setOpenBottombar] = React.useState(false);

  // const handleBottombarOpen = () => {
  //   setOpenBottombar(true);
  // };

  // const handleBottombarClose = () => {
  //   setOpenBottombar(false);
  // };

  return (
    <div className={classes.root}>
      <Hero />
      <Section className={ classes.sectionNoPaddingTop, classes.AdvantagesSection } >
        <Advantages data={advantages} />
      </Section>
      <SectionAlternate className={ classes.sectionNoPaddingTop} >
        <Industries data={industries} />
      </SectionAlternate>
      <Section className={ classes.sectionNoPaddingTop, classes.TechnologiesSection } >
        <Technologies data={technologies} />
      </Section>
      <SectionAlternate className={classes.sectionNoPaddingTop, classes.ContactSection}>
        <Contact id="contact" />
      </SectionAlternate>
    </div>
  );
};

export default withRouter(DataScience);
