import { colors } from '@material-ui/core';

export const technologies = [
  {
    name: 'Python',
    logo: '/images/logos/python-logo.svg',
  },
  {
    name: 'Scikit Learn',
    logo: '/images/logos/scikit-learn-logo.svg',
  },
  {
    name: 'Elastic Stack',
    logo: '/images/logos/elasticsearch2-logo.svg',
  },
  {
    name: 'Elastic Beats',
    logo: '/images/logos/elastic-beats-logo.svg',
  },
  {
    name: 'Elastic Logstash',
    logo: '/images/logos/elastic-logstash-logo.svg',
  },
  {
    name: 'Apache Spark',
    logo: '/images/logos/spark-logo.svg',
  },
];

export const jobCategories = [
  {
    iconColor: colors.blue,
    iconBg: colors.blue,
    icon: 'fas fa-pen-nib',
    title: 'Web Design',
  },
  {
    iconColor: colors.purple,
    iconBg: colors.purple,
    icon: 'fas fa-book-open',
    title: 'Business Analytics',
  },
  {
    iconColor: colors.amber,
    iconBg: colors.amber,
    icon: 'fas fa-camera-retro',
    title: 'Photography',
  },
  {
    iconColor: colors.indigo,
    iconBg: colors.indigo,
    icon: 'fas fa-video',
    title: 'Video Creating',
  },
  {
    iconColor: colors.pink,
    iconBg: colors.pink,
    icon: 'fas fa-heartbeat',
    title: 'Health & Fitness',
  },
  {
    iconColor: colors.green,
    iconBg: colors.green,
    icon: 'fas fa-bullhorn',
    title: 'Marketing',
  },
  {
    iconColor: colors.deepOrange,
    iconBg: colors.deepOrange,
    icon: 'fas fa-funnel-dollar',
    title: 'Finances & Sales',
  },
  {
    iconColor: colors.red,
    iconBg: colors.red,
    icon: 'fas fa-paint-brush',
    title: 'Graphic Design',
  },
];

export const jobs = [
  {
    color: colors.blue[500],
    title: 'web design',
    jobTitle: 'Web designer internship',
    type: 'Full time',
    location: 'Milan, Italy',
    date: '3 days ago',
    companyLogo: '/images/logos/slack.svg',
    companyName: 'Slack',
  },
  {
    color: colors.purple[500],
    title: 'business analytics',
    jobTitle: 'Senior business analysts',
    type: 'Full time',
    location: 'Rome, Italy',
    date: 'Just now',
    companyLogo: '/images/logos/google-ad-manager.svg',
    companyName: 'Google Ads',
  },
  {
    color: colors.amber[500],
    title: 'photography',
    jobTitle: 'Mid-level photographer',
    type: 'Remote',
    location: 'Yerevan, Armenia',
    date: '1 week ago',
    companyLogo: '/images/logos/atlassian.svg',
    companyName: 'Atlassian',
  },
  {
    color: colors.indigo[500],
    title: 'video creating',
    jobTitle: 'Senior video creator',
    type: 'Part time',
    location: 'Paris, France',
    date: '2 days ago',
    companyLogo: '/images/logos/dropbox.svg',
    companyName: 'Dropbox',
  },
  {
    color: colors.pink[500],
    title: 'health & fitness',
    jobTitle: 'Expert fitness consultant',
    type: 'Remote',
    location: 'Yerevan, Armenia',
    date: '1 month ago',
    companyLogo: '/images/logos/google-ad-manager.svg',
    companyName: 'Google Ads',
  },
  {
    color: colors.green[500],
    title: 'marketing',
    jobTitle: 'Senior digital marketing specialist',
    type: 'Full time',
    location: 'Milan, Italy',
    date: '4 days ago',
    companyLogo: '/images/logos/google-drive.svg',
    companyName: 'Google Drive',
  },
  {
    color: colors.deepOrange[500],
    title: 'finances & sales',
    jobTitle: 'Senior sales manager',
    type: 'Part Time',
    location: 'Paris, France',
    date: '5 days ago',
    companyLogo: '/images/logos/mailchimp.svg',
    companyName: 'Mail Chimp',
  },
  {
    color: colors.red[500],
    title: 'graphic design',
    jobTitle: 'Junior graphic designer',
    type: 'Remote',
    location: 'Milan, Italy',
    date: '2 weeks ago',
    companyLogo: '/images/logos/slack.svg',
    companyName: 'Slack',
  },
  {
    color: colors.purple[500],
    title: 'business analytics',
    jobTitle: 'Senior business analysts',
    type: 'Full time',
    location: 'Rome, Italy',
    date: 'Just now',
    companyLogo: '/images/logos/google-ad-manager.svg',
    companyName: 'Google Ads',
  },
];

export const companies = [
  {
    title: 'Photography',
    location: 'Yerevan, Armenia',
    jobs: '3 jobs',
    companyLogo: '/images/logos/atlassian.svg',
    companyName: 'Atlassian',
    subtitle:
      'Keep your entire team in sync with development and easily manage tasks, goals, and deadlines.',
  },
  {
    title: 'Video Creating',
    location: 'Paris, France',
    jobs: '4 jobs',
    companyLogo: '/images/logos/dropbox.svg',
    companyName: 'Dropbox',
    subtitle:
      'Sync any file store to Dropbox for automated sharing with people outside the company.',
  },
  {
    title: 'Health & Fitness',
    location: 'Yerevan, Armenia',
    jobs: '10 jobs',
    companyLogo: '/images/logos/google-ad-manager.svg',
    companyName: 'Google Ads',
    subtitle:
      'Easily manage and edit any Adwords campaign inline to improve ROI with constant review.',
  },
  {
    title: 'Marketing',
    location: 'Milan, Italy',
    jobs: '3 jobs',
    companyLogo: '/images/logos/google-drive.svg',
    companyName: 'Google Drive',
    subtitle:
      'Sync any file store to Google Drive for automated sharing with people outside the company.',
  },
  {
    title: 'Finances & Sales',
    location: 'Paris, France',
    jobs: '2 jobs',
    companyLogo: '/images/logos/mailchimp.svg',
    companyName: 'Mail Chimp',
    subtitle:
      'Communicate important messages to your users through TheFront using Mailchimp as the delivery service.',
  },
  {
    title: 'Graphic Design',
    location: 'Milan, Italy',
    jobs: '8 jobs',
    companyLogo: '/images/logos/slack.svg',
    companyName: 'Slack',
    subtitle:
      "Sync your team's work and activity to share automatically in a channel with a simple plugin.",
  },
];

export const jobs_new = [
  {
    title: 'Business Development Manager',
    location: 'Toronto, Canada (Remote Currently)',
    jobLink: '/',
    companyLogo: '/images/logos/slack.svg',
    groupName: 'Business Development',
    subtitle:
      'DETAILS COMING SOON.',
  },
  // {
  //   title: 'ML Engineer (Junior)',
  //   location: 'Toronto, Canada (Remote Currently)',
  //   jobLink: '/',
  //   companyLogo: '/images/logos/elasticsearch2-logo.svg',
  //   groupName: 'ML Engineering',
  //   subtitle:
  //     'DETAILS COMING SOON.',
  // },
  {
    title: 'Data Scientist (Intermediate)',
    location: 'Toronto, Canada (Remote Currently)',
    jobLink: '/',
    companyLogo: '/images/logos/python-logo.svg',
    groupName: 'Data Science',
    subtitle:
      'DETAILS COMING SOON.',
  },
  // {
  //   title: 'Data Analyst (Intermediate)',
  //   location: 'Toronto, Canada (Remote Currently)',
  //   jobLink: '/',
  //   companyLogo: '/images/logos/tableau-logo.svg',
  //   groupName: 'Data Science',
  //   subtitle:
  //     'DETAILS COMING SOON.',
  // },
  // {
  //   title: 'Data Scientist (Principal)',
  //   location: 'Toronto, Canada (Remote Currently)',
  //   jobLink: '/',
  //   companyLogo: '/images/logos/python-logo.svg',
  //   groupName: 'Data Science',
  //   subtitle:
  //     'DETAILS COMING SOON.',
  // },
  // {
  //   title: 'Data Analyst (Intermediate)',
  //   location: 'Toronto, Canada (Remote Currently)',
  //   jobLink: '/',
  //   companyLogo: '/images/logos/power-bi-logo.svg',
  //   groupName: 'Data Science',
  //   subtitle:
  //     'DETAILS COMING SOON.',
  // },
  {
    title: 'ML Engineer (Intermediate)',
    location: 'Toronto, Canada (Remote Currently)',
    jobLink: '/',
    companyLogo: '/images/logos/elasticsearch2-logo.svg',
    groupName: 'ML Engineering',
    subtitle:
      'DETAILS COMING SOON.',
  },
  // {
  //   title: 'Project Manager (Intermediate)',
  //   location: 'Toronto, Canada (Remote Currently)',
  //   jobLink: '/',
  //   companyLogo: '/images/logos/slack.svg',
  //   groupName: 'Data Science',
  //   subtitle:
  //     'DETAILS COMING SOON.',
  // },
];

export const faq = [
  {
    id: 'faq-1',
    title: 'Challenging Problems',
    subtitle: 'We get to work on some of the most interesting problems!',
    text:
      'If the problems were simple, they would have been solved already. 🤣 🤣 🤣',
    // link: 'Check it out',
  },
  {
    id: 'faq-2',
    title: 'Bootcamp Training',
    subtitle: 'Our team has the same foundational knowledge from the start!',
    text:
      'All new employees spend their first several weeks going through a self-directed technical training program that our founders created. This gives our team a standardized framework that helps drive consistency in our project delivery and allows us to learn together.',
    // link: 'Check it out',
  },
  {
    id: 'faq-3',
    title: 'Employee Development',
    subtitle: 'Our business model relies on our team being knowledgeable and continuously learning!',
    text:
      ' We encourage all employees to continue investing in themselves through learning in a classroom setting, online courses, and subscriptions using allocated built-in training time.',
    // link: 'Check it out',
  },
];
