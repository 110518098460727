import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { LearnMoreLink } from 'components/atoms';
import { SectionHeader, TypedText } from 'components/molecules';
import { CardJobGroup } from 'components/organisms';

import { 
  useMediaQuery, 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  typed: {
    fontWeight: 'bold',
  },
}));

const Careers = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openCareer, setOpenCareer] = React.useState(false);

  const handleClickOpenCareer = () => {
    setOpenCareer(true);
  };

  const handleCloseCareer = () => {
    setOpenCareer(false);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title={
          <>
            Technologies {isMd ? null : <br />} you love &nbsp;
            <TypedText
              component="span"
              variant="h4"
              color="secondary"
              className={classes.typed}
              typedProps={{
                strings: [
                  'Python',
                  'Scikit Learn',
                  'Elastic Stack',
                  'Spark',
                  'Machine Learning',
                  'Supervised Learning',
                  'Unsupervised Learning',
                  'Reinforcement Learning',
                  "More..."
                ],
                typeSpeed: 50,
                loop: true,
              }}
            />
          </>
        }
        subtitle={
          <span>
            Find your new home.
          </span>
        }
        align="left"
      />
      <Grid container spacing={isMd ? 4 : 2}>
        {data.map((item, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            md={4}
            data-aos="fade-up"
          >
            <CardJobGroup
              variant="outlined"
              liftUp
              jobTitle={item.groupName}
              jobLocation={item.location} 
              companyLogo={item.companyLogo}
              groupName={item.title}
              jobsLink={'Learn More'}
              companyInfo={item.subtitle}
            >
            <LearnMoreLink title={'Learn more'} variant="body1" onClick={handleClickOpenCareer} />
            <Dialog
              open={openCareer}
              onClose={handleCloseCareer}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" align="center">
                Job
                <Divider />
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <strong><u>Examples of AI-based tools include but not limited to the following:</u></strong>
                  <br /><br />
                  <p>
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseCareer} color="primary" autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            </CardJobGroup>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Careers.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Careers;
